import { createSelector } from '@ngrx/store';
import { UserDTO } from 'src/app/commons/models/user.model';

import { selectUserState } from '../reducers';
import { UserState } from '../reducers/user.reducer';
import { ParticipationDTO } from './../../commons/models/participation.model';

// User Selectors

export const getUsersTableState = createSelector(
  selectUserState,
  (state: UserState) => { return { total: state.total, currentPage: state.currentPage, perPage: state.perPage, order: state.order, direction: state.direction, filters: state.filters } }
);

export const getUsers = createSelector(
  selectUserState,
  (state: UserState) => state.list
);

export const getTotalUsers = createSelector(
  getUsersTableState,
  (tableState) => tableState.total
);

export const getUsersCurrentPage = createSelector(
  getUsersTableState,
  (tableState) => tableState.currentPage
);

export const getUsersPerPage = createSelector(
  getUsersTableState,
  (tableState) => tableState.perPage
);

export const getUsersOrder = createSelector(
  getUsersTableState,
  (tableState) => tableState.order
);

export const getUsersDirection = createSelector(
  getUsersTableState,
  (tableState) => tableState.direction
);

export const getFilters = createSelector(
  getUsersTableState,
  (tableState) => tableState.filters
);

export const getUserDialogId = createSelector(
  selectUserState,
  (state: UserState) => state.dialogId
);

export const getSelectedUser = createSelector(
  selectUserState,
  (state: UserState) => state.selectedUser
);

const getParticipations = createSelector(
  selectUserState,
  (state: UserState) => state.participations
);

export const getUserParticipations = createSelector(
  getSelectedUser,
  getParticipations,
  (user: UserDTO, participations: ParticipationDTO[]) => {
    if (user) {
      if (participations) {
        return participations.filter(p => p.user_id == user.id);
      }
    }
    return null;
  }
);

export const getUserParticipationsLoaded = createSelector(
  getUserParticipations,
  (participations: ParticipationDTO[]) => participations != null && participations.length > 0
);

export const getParticipationsDialogId = createSelector(
  selectUserState,
  (state: UserState) => state.participationsDialogId
);

export const getChangePasswordDialogId = createSelector(
  selectUserState,
  (state: UserState) => state.changePasswordDialogId
);
