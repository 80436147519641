import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { saveAs } from 'file-saver';
import { of } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { LaravelEventService } from 'src/app/commons/services/backend/laravel-event.service';

import { AlertService } from '../../commons/services/alert.service';
import { ParticipationService } from '../../commons/services/backend/laravel-participation.service';
import * as ParticipationActions from '../actions/participation.actions';
import * as RouterActions from '../actions/router.actions';
import { AppState } from '../reducers';
import { environment } from './../../../environments/environment';


@Injectable()
export class ParticipationEffects {

  error$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ParticipationActions.saveParticipationFailed, ParticipationActions.loadTotalsFailed, ParticipationActions.deleteParticipationFailed, ParticipationActions.loadUserParticipationsFailed, ParticipationActions.loadEventsFailed, ParticipationActions.loadParticipationFailed, ParticipationActions.loadEventFailed),
      tap(({ error }) => {
        if (error) {
          this.alertService.showErrorMessage('Errore', error);
        }
      })
    ), { dispatch: false }
  );

  loadTotals$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ParticipationActions.loadTotals),
      switchMap(() => {
        return this.participationService.totals()
          .pipe(
            map(totals =>
              ParticipationActions.loadTotalsCompleted({ totals })
            ),
            catchError(error => {
              return of(ParticipationActions.loadTotalsFailed({ error }))
            })
          )
      })
    )
  );

  loadEvent$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ParticipationActions.loadEvent),
      switchMap(({ id }) => {
        return this.eventService.getById(id)
          .pipe(
            map(event =>
              ParticipationActions.loadEventCompleted({ event })
            ),
            catchError(error => of(ParticipationActions.loadEventFailed({ error })))
          )
      })
    )
  );

  loadParticipation$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ParticipationActions.loadParticipation),
      switchMap(({ eventId, userId }) => {
        return this.participationService.getByEventAndUser(eventId, userId)
          .pipe(
            map(eventParticipation =>
              ParticipationActions.loadParticipationCompleted({ eventParticipation })
            ),
            catchError(error => of(ParticipationActions.loadParticipationFailed({ error })))
          )
      })
    )
  );

  // loadOnShow = createEffect(() =>
  //   this.actions$.pipe(
  //     ofType(ParticipationActions.showParticipation),
  //     map(({ id }) => ParticipationActions.loadParticipation({ id }))
  //   )
  // );

  showEvent = createEffect(() =>
    this.actions$.pipe(
      ofType(ParticipationActions.showEvent),
      map(({ event }) => RouterActions.routerGo({ path: [`details/${event.id}`] }))
    )
  );

  loadEvents$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ParticipationActions.loadEvents),
      switchMap(() => {
        return this.eventService.listAll()
          .pipe(
            map(events =>
              ParticipationActions.loadEventsCompleted({ events })
            ),
            catchError(error => of(ParticipationActions.loadEventsFailed({ error })))
          )
      })
    )
  );

  loadParticipationAttachments$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ParticipationActions.loadAttachments),
      switchMap(({ participationId }) => {
        return this.participationService.attachments(participationId)
          .pipe(
            map(result =>
              ParticipationActions.loadAttachmentsCompleted({ attachments: result.data })
            ),
            catchError(error => of(ParticipationActions.loadAttachmentsFailed({ error })))
          )
      })
    )
  );

  loadUserParticipations$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ParticipationActions.loadUserParticipations),
      switchMap(({ user }) => {
        return this.participationService.getByUser(user.id)
          .pipe(
            map(participations =>
              ParticipationActions.loadUserParticipationsCompleted({ participations })
            ),
            catchError(error => of(ParticipationActions.loadUserParticipationsFailed({ error })))
          )
      })
    )
  );


  // addParticipation$ = createEffect(() => this.actions$.pipe(
  //   ofType(ParticipationActions.addParticipation),
  //   map(() => {
  //     let dialogRef = this.dialog.open(ParticipationEditComponent, {});
  //     return ParticipationActions.participationDialogOpened({ dialogId: dialogRef.id });
  //   }))
  // );

  // editParticipation$ = createEffect(() => this.actions$.pipe(
  //   ofType(ParticipationActions.editParticipation),
  //   map(({ participation }) => {
  //     let dialogRef = this.dialog.open(ParticipationEditComponent, {
  //       data: {
  //         participation
  //       }
  //     });
  //     return ParticipationActions.participationDialogOpened({ dialogId: dialogRef.id });
  //   }))
  // );


  // editAttachments$ = createEffect(() => this.actions$.pipe(
  //     ofType(ParticipationActions.editAttachments),
  //     tap(({ participation }) => {
  //         this.modalService.showModal({ component: ParticipationAttachmentsComponent, backdropDismiss: false, componentProps: { participation } }).subscribe()
  //     })
  // ), { dispatch: false }
  // );

  // closeModal$ = createEffect(() =>
  //     this.actions$.pipe(
  //         ofType(ParticipationActions.closeParticipationDialog, ParticipationActions.saveParticipationCompleted, ParticipationActions.closeAttachmentsDialog),
  //         tap(() => this.modalService.dismissModal())
  //     ), { dispatch: false }
  // );

  confirmParticipation$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ParticipationActions.confirmParticipation),
      switchMap(({ event, user }) =>
        this.participationService.upsert({
          event_id: event.id,
          user_id: user.id
        }).pipe(
          map(participation =>
            ParticipationActions.saveParticipationCompleted({ participation })
          ),
          catchError(error => of(ParticipationActions.saveParticipationFailed({ error })))
        )
      )
    )
  );


  saveParticipation$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ParticipationActions.saveParticipation),
      switchMap(({ participation }) =>
        this.participationService.upsert(participation)
          .pipe(
            map(result =>
              ParticipationActions.saveParticipationCompleted({ participation: result })
            ),
            catchError(error => of(ParticipationActions.saveParticipationFailed({ error })))
          )
      )
    )
  );

  onSaveCompleted$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ParticipationActions.saveParticipationCompleted),
      tap(({ participation }) => this.alertService.showConfirmMessage(`Partecipazione a ${participation.event.name} salvata con successo`)),
    ), { dispatch: false }
  );

  reloadParticipationAfterSave$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ParticipationActions.saveParticipationCompleted, ParticipationActions.updateCommentCompleted),
      map(({ participation }) => ParticipationActions.loadParticipation({ eventId: participation.event_id, userId: participation.user_id }))
    )
  );

  updateComment$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ParticipationActions.updateComment),
      switchMap(({ participationId, comment }) =>
        this.participationService.updateComment(participationId, comment)
          .pipe(
            map(participation =>
              ParticipationActions.updateCommentCompleted({ participation })
            ),
            catchError(error => of(ParticipationActions.updateCommentFailed({ error })))
          )
      )
    )
  );

  addAttachment$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ParticipationActions.addAttachment),
      switchMap(({ participationId, file }) =>
        this.participationService.addAttachments(participationId, [file], "document")
          .pipe(
            map(attachments =>
              ParticipationActions.addAttachmentCompleted({ attachment: attachments[0], participationId })
            ),
            catchError(error => of(ParticipationActions.addAttachmentFailed({ error })))
          )
      )
    )
  );

  addImage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ParticipationActions.addImage),
      switchMap(({ participationId, file }) =>
        this.participationService.addAttachments(participationId, [file], "image")
          .pipe(
            map(attachments =>
              ParticipationActions.addAttachmentCompleted({ attachment: attachments[0], participationId })
            ),
            catchError(error => of(ParticipationActions.addAttachmentFailed({ error })))
          )
      )
    )
  );

  removeAttachment$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ParticipationActions.removeAttachment),
      switchMap(({ attachment }) =>
        this.alertService.showConfirmDialog('Conferma eliminazione', `Sei sicuro di voler eliminare ${attachment.type == 'image' ? 'l\'immagine?' : 'l\'allegato'}`)
          .pipe(
            switchMap((confirm) => {
              return confirm ?
                this.participationService.removeAttachment(attachment.participation_id, attachment.id)
                  .pipe(
                    map(() => ParticipationActions.removeAttachmentCompleted({ attachment })),
                    catchError(error => of(ParticipationActions.removeAttachmentFailed({ error })))
                  )
                : of(ParticipationActions.removeAttachmentCancelled());
            })
          )
      )
    )
  );

  downloadAttachment$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ParticipationActions.downloadAttachment),
      tap(({ attachment }) => {
        saveAs(`${environment.baseUrl}/storage/attachments/${attachment.participation_id}/${attachment.file_url}`);
      })
    ), { dispatch: false }
  );

  delete$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ParticipationActions.deleteParticipation),
      switchMap(({ participation }) =>
        this.alertService.showConfirmDialog('Conferma eliminazione', `Sei sicuro di voler eliminare la partecipazione a ${participation.event.name}?`)
          .pipe(
            switchMap((confirm) => {
              return confirm ?
                this.participationService.delete(participation.id)
                  .pipe(
                    map(() => ParticipationActions.deleteParticipationCompleted({ participation })),
                    catchError(error => of(ParticipationActions.deleteParticipationFailed({ error })))
                  )
                : of(ParticipationActions.deleteParticipationCancelled());
            })
          )
      )
    )
  );

  onDeleteCompleted$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ParticipationActions.deleteParticipationCompleted),
      tap(({ participation }) => this.alertService.showConfirmMessage(`Partecipazione a ${participation.event.name} eliminata con successo`)),
      map(() => RouterActions.routerGo({ path: ['/profile'] }))
    )
  );

  constructor(
    private actions$: Actions,
    private store$: Store<AppState>,
    private participationService: ParticipationService,
    private eventService: LaravelEventService,
    private alertService: AlertService,
  ) { }
}
