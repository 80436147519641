import { Action, createReducer, on } from '@ngrx/store';
import { UserDTO } from 'src/app/commons/models/user.model';
import { ParticipationTotalsDTO } from 'src/app/commons/services/backend/laravel-participation.service';

import * as AuthActions from '../actions/auth.actions';
import * as ParticipationActions from '../actions/participation.actions';
import { EventDTO, ParticipationDTO } from './../../commons/models/participation.model';

export interface ParticipationState {
  selectedUser: UserDTO,
  eventParticipation: ParticipationDTO,
  selectedEvent: EventDTO,
  events: EventDTO[],
  totals: ParticipationTotalsDTO
};

const initialState: ParticipationState = {
  selectedUser: null,
  selectedEvent: null,
  eventParticipation: null,
  events: null,
  totals: null
};

const participationReducer = createReducer(
  initialState,
  on(AuthActions.loadCurrentUserCompleted, (state, { currentUser }) => {
    return { ...state, selectedUser: currentUser };
  }),
  on(AuthActions.loginCompleted, (state, { currentUser }) => {
    return { ...state, selectedUser: currentUser };
  }),
  on(ParticipationActions.loadTotalsCompleted, (state, { totals }) => {
    return { ...state, totals };
  }),
  on(ParticipationActions.loadEventCompleted, (state, { event }) => {
    return { ...state, selectedEvent: event };
  }),
  on(ParticipationActions.loadParticipationCompleted, (state, { eventParticipation }) => {
    return { ...state, eventParticipation };
  }),
  on(ParticipationActions.loadEventsCompleted, (state, { events }) => {
    return { ...state, events };
  }),
  on(ParticipationActions.loadUserParticipationsCompleted, (state, { participations }) => {
    let selectedUser: UserDTO = Object.assign(JSON.parse(JSON.stringify(state.selectedUser)));
    selectedUser.participations = participations
    return { ...state, selectedUser };
  }),
  on(ParticipationActions.saveParticipationCompleted, (state, { participation }) => {
    if (state.selectedUser && participation.user_id == state.selectedUser.id) {
      let newUser: UserDTO = Object.assign(JSON.parse(JSON.stringify(state.selectedUser)));
      if (state.selectedUser.participations) {
        const oldParticipation = state.selectedUser.participations.find(p => p.id === participation.id);
        const oldParticipationIndex = state.selectedUser.participations.indexOf(oldParticipation);
        if (oldParticipationIndex != -1) {
          newUser.participations = [
            ...state.selectedUser.participations.slice(0, oldParticipationIndex),
            participation,
            ...state.selectedUser.participations.slice(oldParticipationIndex + 1)
          ]
        } else {
          newUser.participations.push(participation);
        }
      } else {
        newUser.participations = [participation];
      }
      return { ...state, selectedUser: newUser }
    }
    return { ...state }
  }),
  on(ParticipationActions.saveParticipationCompleted, (state, { participation }) => {
    if (state.eventParticipation && state.eventParticipation.id === participation.id) {
      return { ...state, eventParticipation: participation };
    }
    else {
      return { ...state }
    }
  }),
  on(ParticipationActions.deleteParticipationCompleted, (state, { participation }) => {
    if (state.eventParticipation && participation.id == state.eventParticipation.id) {
      return { ...state, eventParticipation: null }
    }
    return { ...state }
  }),
  on(ParticipationActions.deleteParticipationCompleted, (state, { participation }) => {
    if (state.selectedUser && state.selectedUser.participations && state.selectedUser.participations.map(p => p.id).indexOf(participation.id) != -1) {
      let newUser: UserDTO = Object.assign(JSON.parse(JSON.stringify(state.selectedUser)));
      newUser.participations = state.selectedUser.participations.filter(p => p.id != participation.id);
      return { ...state, selectedUser: newUser }
    }
    return { ...state }
  }),
  on(ParticipationActions.loadAttachmentsCompleted, (state, { attachments }) => {
    return { ...state, attachments };
  }),
  on(ParticipationActions.addAttachmentCompleted, (state, { attachment, participationId }) => {
    if (state.eventParticipation && state.eventParticipation.id == participationId) {
      let newParticipation: ParticipationDTO = Object.assign(JSON.parse(JSON.stringify(state.eventParticipation)));
      newParticipation.attachments = state.eventParticipation.attachments.concat(Object.assign({}, attachment));
      return { ...state, eventParticipation: newParticipation }
    }
    return { ...state }
  }),
  on(ParticipationActions.addImageCompleted, (state, { attachment, participationId }) => {
    if (state.eventParticipation && state.eventParticipation.id == participationId) {
      let newParticipation: ParticipationDTO = Object.assign(JSON.parse(JSON.stringify(state.eventParticipation)));
      newParticipation.attachments = state.eventParticipation.attachments.concat(Object.assign({}, attachment));
      return { ...state, eventParticipation: newParticipation }
    }
    return { ...state }
  }),
  on(ParticipationActions.removeAttachmentCompleted, (state, { attachment }) => {
    if (state.eventParticipation && state.eventParticipation.id == attachment.participation_id) {
      let newParticipation: ParticipationDTO = Object.assign(JSON.parse(JSON.stringify(state.eventParticipation)));
      newParticipation.attachments = state.eventParticipation.attachments.filter(a => a.id !== attachment.id)
      return { ...state, eventParticipation: newParticipation }
    }
    return { ...state }
  })
);

export function reducer(state: ParticipationState | undefined, action: Action) {
  return participationReducer(state, action);
}

