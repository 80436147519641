import { Action, createReducer, on } from '@ngrx/store';

import { UserDTO } from '../../commons/models/user.model';
import * as AuthActions from '../actions/auth.actions';

export interface AuthState {
  token: string,
  currentUser: UserDTO,
};

const initialState: AuthState = {
  token: null,
  currentUser: null,
};

const authReducer = createReducer(
  initialState,
  on(AuthActions.loginCompleted, (state, { token, currentUser }) => {
    return { ...state, token, currentUser };
  }),
  on(AuthActions.logoutCompleted, (state) => {
    return { ...state, token: null, currentUser: null };
  }),
  on(AuthActions.loadCurrentUserCompleted, (state, { currentUser }) => {
    return { ...state, currentUser }
  }),
);

export function clearState(reducer) {
  return function (state, action) {
    if (action.type === AuthActions.logoutCompleted().type) {
      state = undefined;
    }

    return reducer(state, action);
  };
}


export function reducer(state: AuthState | undefined, action: Action) {
  return authReducer(state, action);
}
