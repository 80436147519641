import { AbstractControl } from '@angular/forms';
import { formatDateForBackend } from 'src/app/helpers/time.utils';
import { environment } from 'src/environments/environment';

import { Base, BaseDTO } from './base.model';
import { User, UserDTO } from './user.model';

export interface ParticipationFilters {
  event?: EventDTO
}
export interface EventDTO extends BaseDTO {
  name: string;
  title: string;
  description: string;
  date: string;
  image?: any;
  image_url?: string;
  participations?: ParticipationDTO[];
  participations_count: number;
  images_count: number;
  comments_count: number;
  attachments_count: number;
}

export class Event extends Base {
  name: string;
  title: string;
  description: string;
  date: Date;
  image?: any;
  imageUrl?: string;
  participations?: Participation[];
  participationsCount: number;
  imagesCount: number;
  commentsCount: number;
  attachmentsCount: number;

  constructor(dto: EventDTO) {
    super(dto)
    if (dto) {
      this.name = dto.name;
      this.title = dto.title;
      this.description = dto.description;
      this.imageUrl = dto.image_url;
      if (dto.date) {
        this.date = new Date(dto.date);
      }
      if (dto.participations) {
        this.participations = dto.participations.map(p => new Participation(p));
        this.addLoadedRelation("participations");
      }
      this.participationsCount = dto.participations_count;
      this.imagesCount = dto.images_count;
      this.commentsCount = dto.comments_count;
      this.attachmentsCount = dto.attachments_count;
    }
  }

  toDTO(): EventDTO {
    let result: EventDTO = <EventDTO>super.toDTO();
    result.name = this.name;
    result.title = this.title;
    result.description = this.description;
    result.image_url = this.imageUrl;
    result.image = this.image;
    result.date = this.date ? formatDateForBackend(this.date) : null;
    result.participations = this.participations ? this.participations.map(p => p.toDTO()) : null;
    result.participations_count = this.participationsCount;
    result.images_count = this.imagesCount;
    result.comments_count = this.commentsCount;
    result.attachments_count = this.attachmentsCount;
    return result;
  }

  static fromFormGroup(formGroup: AbstractControl, original?: Event) {
    const formModel = formGroup.value;
    let event: Event = new Event(null);
    event.name = formModel.name;
    event.title = formModel.title;
    event.description = formModel.description;
    event.date = formModel.date;
    event.image = formModel.image;
    if (original) {
      event.id = original.id;
    }
    return event;
  }

  static DTOtoFormData(dto: EventDTO): FormData {
    let formData: FormData = new FormData();
    if (dto) {
      if (dto.id) formData.append("id", "" + dto.id);
      if (dto.image) formData.append("image", dto.image);
      if (dto.description) formData.append("description", dto.description);
      if (dto.name) formData.append("name", dto.name);
      if (dto.title) formData.append("title", dto.title);
      if (dto.date) formData.append("date", dto.date);
    }
    return formData;
  }

  get completeUrl(): string {
    return this.imageUrl ? `${environment.baseUrl}/storage/events/images/${this.id}/${this.imageUrl}` : null;
  }
}

export interface ParticipationDTO extends BaseDTO {
  user_id: number;
  participant?: UserDTO;
  event_id: number;
  event?: EventDTO;
  comment?: string;

  attachments?: AttachmentDTO[];
  comment_approved?: boolean;
}

export class Participation extends Base {
  user: User;
  eventId: number;
  event: Event;
  comment: string;
  attachments: Attachment[];
  commentApproved: boolean;

  constructor(dto: ParticipationDTO) {
    super(dto)
    if (dto) {
      if (dto.participant) {
        this.user = new User(dto.participant);
        this.addLoadedRelation("participant");
      }
      this.eventId = dto.event_id;
      if (dto.event) {
        this.event = new Event(dto.event);
        this.eventId = this.event.id;
        this.addLoadedRelation("event");
      }
      if (dto.attachments) {
        this.attachments = dto.attachments.map(a => new Attachment(a));
        this.addLoadedRelation("attachments");
      }
      this.comment = dto.comment;
      this.commentApproved = dto.comment_approved;
    }
  }

  toDTO(): ParticipationDTO {
    let result: ParticipationDTO = <ParticipationDTO>super.toDTO();
    result.comment = this.comment;
    result.event = this.event ? this.event.toDTO() : null;
    result.event_id = this.event ? this.event.id : null;
    result.participant = this.user ? this.user.toDTO() : null;
    result.user_id = this.user ? this.user.id : null;
    result.attachments = this.attachments ? this.attachments.map(a => a.toDTO()) : null;
    result.comment_approved = this.commentApproved;
    return result;
  }

  static fromFormGroup(formGroup: AbstractControl, original?: Participation) {
    const formModel = formGroup.value;
    let participation: Participation = new Participation(null);
    participation.comment = formModel.comment;
    participation.event = formModel.event;
    if (original) {
      participation.id = original.id;
    }
    return participation;
  }

  get image(): Attachment {
    return this.attachments ? this.attachments.find(a => a.type == 'image') : null;
  }

  get document(): Attachment {
    return this.attachments ? this.attachments.find(a => a.type == 'document') : null;
  }

  get contributed(): boolean {
    return !!this.image || !!this.comment || !!this.document;
  }
}

export type AttachmentType = "image" | "document";
export interface AttachmentDTO extends BaseDTO {
  file: any;
  file_url: string;
  participation_id: number;
  type: AttachmentType;
  approved: boolean;
}

export class Attachment extends Base {
  fileUrl: string;
  file: any;
  participationId: number;
  type: AttachmentType;
  approved: boolean;
  constructor(dto: AttachmentDTO) {
    super(dto);
    if (dto) {
      this.fileUrl = dto.file_url;
      this.participationId = dto.participation_id;
      this.type = dto.type;
      this.approved = dto.approved;
    }
  }

  public toDTO(): AttachmentDTO {
    let dto: AttachmentDTO = <AttachmentDTO>super.toDTO();
    dto.file_url = this.fileUrl;
    dto.participation_id = this.participationId;
    dto.type = this.type;
    dto.approved = this.approved;
    return dto;
  }

  get completeUrl(): string {
    return `${environment.baseUrl}/storage/attachments/${this.participationId}/${this.fileUrl}`;
  }
}
