import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { Observable } from 'rxjs';

import { Participant } from '../../../commons/models/participant.model';
import { PAGE_SIZE_OPTIONS } from '../../../helpers/table.helper';
import { ParticipantFilters } from './../../../commons/models/participant.model';

export type ParticipantsColumn = "name" | "registration_date" | "email" | "mobile" | "type" | "total" | "slot" | "actions";
@Component({
  selector: 'thc-participant-list',
  templateUrl: './participant-list.component.html',
  styleUrls: ['./participant-list.component.scss']
})
export class ParticipantListComponent {
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  @Input()
  participants: Observable<Participant[]>;

  @Input()
  defaultFilters: ParticipantFilters;

  pageSizeOptions = PAGE_SIZE_OPTIONS;

  @Input()
  total: Observable<number>;
  @Input()
  displayedColumns: ParticipantsColumn[] = ["name", "email", "registration_date", "mobile", "type", "total", "slot", "actions"];

  @Input()
  canAdd: boolean;
  @Input()
  canEdit: boolean;
  @Input()
  canSelect: boolean;
  @Input()
  maxSize: boolean;

  @Output()
  onLoad: EventEmitter<any> = new EventEmitter<any>();
  @Output()
  onSortChange: EventEmitter<Sort> = new EventEmitter<Sort>();
  @Output()
  onPageChange: EventEmitter<PageEvent> = new EventEmitter<PageEvent>();
  @Output()
  onFilterChange: EventEmitter<ParticipantFilters> = new EventEmitter<ParticipantFilters>();

  @Output()
  onSelectParticipant: EventEmitter<Participant> = new EventEmitter<Participant>();
  @Output()
  onAddParticipant: EventEmitter<any> = new EventEmitter<any>();
  @Output()
  onEditParticipant: EventEmitter<Participant> = new EventEmitter<Participant>();
  @Output()
  onAddUser: EventEmitter<Participant> = new EventEmitter<Participant>();

  constructor() { }


  ngAfterViewInit() {
    this.sort.sortChange.subscribe((sort) => {
      this.onSortChange.emit(sort);
    });
    this.paginator.page.subscribe((pageEvent) => {
      this.onPageChange.emit(pageEvent);
    });
  }

  addParticipant() {
    this.onAddParticipant.emit();
  }

  editParticipant(participant: Participant) {
    this.onEditParticipant.emit(participant);
  }

  selectParticipant(participant: Participant) {
    this.onSelectParticipant.emit(participant);
  }

  addUser(participant: Participant) {
    this.onAddUser.emit(participant);
  }

  onFilter(filters: ParticipantFilters) {
    this.paginator.firstPage();
    this.onFilterChange.emit(filters);
  }
}
