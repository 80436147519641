import { Component, Inject, OnInit, Optional } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { select, Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { AlertService } from 'src/app/commons/services/alert.service';
import * as UserActions from 'src/app/store/actions/user.actions';
import { AppState } from 'src/app/store/reducers';
import * as AuthSelectors from 'src/app/store/selectors/auth.selectors';

import { Role, ROLES, User } from '../../../../commons/models/user.model';

@Component({
  selector: 'app-user-edit',
  templateUrl: './user-edit.component.html',
  styleUrls: ['./user-edit.component.scss']
})
export class UserEditComponent implements OnInit {
  private unsubscribe$ = new Subject<void>();

  private _user: User;

  currentUser: User;

  userForm: FormGroup;
  roles: Role[] = ROLES;
  constructor(private store$: Store<AppState>, private alertService: AlertService, private fb: FormBuilder, @Optional() @Inject(MAT_DIALOG_DATA) public data: any) {
    this._createForm();
    this.store$.pipe(select(AuthSelectors.getCurrentUser), takeUntil(this.unsubscribe$), map(dto => dto ? new User(dto) : null)).subscribe(user => this.currentUser = user);
  }

  ngOnInit() {
    if (this.data) {
      this.user = this.data.user;
    }
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  private _createForm() {
    let group = {
      name: ["", Validators.required],
      surname: ["", Validators.required],
      email: ["", [Validators.required, Validators.email]],
      role: ["", Validators.required],
      city: [""],
      province: [""],
      region: [""],
      mobile: [""],
      nation: [""],
      groupName: [""],
      registrationDate: [""],
    }

    this.userForm = this.fb.group(group);
  }

  private ngOnChanges() {
    if (this.userForm) {
      this.userForm.reset();
      if (this.user) {
        this._initFormValues(this.user);
      } else {
        this.userForm.addControl("password", new FormControl("", Validators.required));
        this.userForm.addControl("confirmPass", new FormControl("", Validators.required));
        this.userForm.setValidators([this._checkIfMatchingPasswords("password", "confirmPass")]);
        this.userForm.updateValueAndValidity();
      }
    }
  }


  private _checkIfMatchingPasswords(
    passwordKey: string,
    passwordConfirmationKey: string
  ): ValidatorFn {
    return (control: FormGroup): { [key: string]: boolean } | null => {
      let passwordInput = control.controls[passwordKey],
        passwordConfirmationInput = control.controls[passwordConfirmationKey];
      if (passwordInput.value !== passwordConfirmationInput.value) {
        return { notEquivalent: true };
      } else {
        return null;
      }
    };
  }

  private _initFormValues(user: User) {
    if (user) {
      this.userForm.patchValue({
        name: user.name,
        surname: user.surname,
        email: user.email,
        role: user.role,
        city: user.city,
        province: user.province,
        mobile: user.mobile,
        region: user.region,
        nation: user.nation,
        groupName: user.groupName,
        registrationDate: user.registrationDate,
      });
    }
  }

  get user(): User {
    return this._user;
  }

  set user(user: User) {
    this._user = user;
    this.ngOnChanges();
  }

  private _prepareSaveUser(): User {
    let savingUser: User = User.fromFormGroup(this.userForm, this.user);
    return savingUser;
  }

  save() {
    let unsavedEntity = this._prepareSaveUser();
    this.store$.dispatch(UserActions.saveUser({ user: unsavedEntity }));
  }

  close() {
    if (this.userForm.pristine) {
      this.store$.dispatch(UserActions.closeUserDialog())
    } else {
      this.alertService
        .showConfirmDialog(
          "Chiudi",
          "Ci sono modifiche non salvate. Sei sicuro di voler chiudere?"
        )
        .subscribe(result => {
          if (result) {
            this.store$.dispatch(UserActions.closeUserDialog())
          }
        });
    }
  }

  deleteUser() {
    this.store$.dispatch(UserActions.deleteUser({ user: this.user }));
  }

  changePassword() {
    if (this.user) {
      this.store$.dispatch(UserActions.changeUserPassword({ user: this.user.toDTO() }));
    }
  }

  revert() {
    this.ngOnChanges();
  }
}
