import { Action, createReducer, on } from '@ngrx/store';

import { UserDTO } from '../../commons/models/user.model';
import * as EventActions from '../actions/event.actions';
import * as UserActions from '../actions/user.actions';
import { ParticipationDTO } from './../../commons/models/participation.model';
import { UserFilters } from './../../commons/models/user.model';

export interface UserState {
  list: UserDTO[],
  total: number,
  currentPage: number,
  perPage: number,
  order: string,
  direction: string,
  filters: UserFilters,
  dialogId: string,
  changePasswordDialogId: string,
  selectedUser: UserDTO,
  participations: ParticipationDTO[],
  participationsDialogId: string
};

const initialState: UserState = {
  list: [],
  total: 0,
  currentPage: 1,
  perPage: 25,
  order: null,
  direction: null,
  filters: null,
  dialogId: null,
  changePasswordDialogId: null,
  selectedUser: null,
  participations: null,
  participationsDialogId: null
};

const userReducer = createReducer(
  initialState,
  on(UserActions.loadUsersCompleted, (state, { users, currentPage, total, perPage, order, direction, filters }) => {
    return { ...state, list: users, currentPage, total, perPage, order, direction, filters };
  }),
  on(UserActions.changeFilters, (state, { filters }) => {
    return { ...state, currentPage: 1, filters };
  }),
  on(UserActions.userDialogOpened, (state, { dialogId }) => {
    return { ...state, dialogId };
  }),
  on(UserActions.changePasswordDialogOpen, (state, { dialogId }) => {
    return { ...state, changePasswordDialogId: dialogId };
  }),
  on(UserActions.showParticipations, (state, { user }) => {
    return { ...state, selectedUser: user };
  }),
  on(UserActions.loadParticipationsCompleted, (state, { participations }) => {
    return { ...state, participations };
  }),
  on(EventActions.approveAttachmentCompleted, (state, { attachment }) => {
    if (attachment) {
      if (state.participations) {
        const oldParticipation = state.participations.find(p => p.id == attachment.participation_id);
        if (oldParticipation) {
          const oldParticipationIndex = state.participations.indexOf(oldParticipation);
          let newParticipation: ParticipationDTO = JSON.parse(JSON.stringify(oldParticipation));
          const oldAttachment = newParticipation.attachments.find(a => a.id == attachment.id);
          if (oldAttachment) {
            const oldAttachmentIndex = newParticipation.attachments.indexOf(oldAttachment);
            newParticipation.attachments =
              newParticipation.attachments = [
                ...newParticipation.attachments.slice(0, oldAttachmentIndex),
                attachment,
                ...newParticipation.attachments.slice(oldAttachmentIndex + 1)
              ]
          } else {
            newParticipation.attachments.push(attachment);
          }
          const participations = [
            ...state.participations.slice(0, oldParticipationIndex),
            newParticipation,
            ...state.participations.slice(oldParticipationIndex + 1)
          ]
          return { ...state, participations };
        }
      }
    }
    return { ...state };
  }),
  on(UserActions.participationsDialogOpened, (state, { dialogId }) => {
    return { ...state, participationsDialogId: dialogId };
  }),
);

export function reducer(state: UserState | undefined, action: Action) {
  return userReducer(state, action);
}

