import { Component, ElementRef, HostListener, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { filter } from 'rxjs/operators';
import { AlertService } from 'src/app/commons/services/alert.service';

@Component({
  selector: "thc-image-upload",
  templateUrl: "./image-upload.component.html",
  styleUrls: ["./image-upload.component.scss"],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: ImageUploadComponent,
      multi: true
    }
  ]
})
export class ImageUploadComponent implements ControlValueAccessor {
  DEFAULT_PLACEHOLDER_SRC = "assets/images/peace_flag.png";
  imageSrc: string | ArrayBuffer = this.DEFAULT_PLACEHOLDER_SRC;
  onChange: Function;
  file: File | null = null;
  disabled: boolean = false;

  @Input()
  deleteConfirmMessage: string;

  @HostListener("change", ["$event.target.files"]) emitFiles(event: FileList) {
    const file = event && event.item(0);
    this.file = file;
    this.onChange(this.file);
  }

  constructor(private host: ElementRef<HTMLInputElement>, private alertService: AlertService) { }

  ngOnInit() { }

  writeValue(value) {
    // clear file input
    this.host.nativeElement.value = "";
    this.file = value;

    if (value) {
      if (typeof value == "string") {
        this.imageSrc = value;
      } else {
        let reader = new FileReader();
        reader.readAsDataURL(value);
        reader.onload = () => {
          this.imageSrc = reader.result;
        };
      }
    }
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  registerOnChange(fn: Function) {
    this.onChange = fn;
  }

  registerOnTouched(fn: Function) { }

  onFileChange(event) {
    let reader = new FileReader();

    if (event.target.files && event.target.files.length) {
      const [file] = event.target.files;
      reader.readAsDataURL(file);

      reader.onload = () => {
        this.imageSrc = reader.result;
      };

      this.registerOnTouched;
    }
  }

  delete() {
    if (this.deleteConfirmMessage) {
      this.alertService.showConfirmDialog('Conferma eliminazione', this.deleteConfirmMessage)
        .pipe(filter((confirm) => confirm))
        .subscribe(() => this._doDelete());
    } else {
      this._doDelete();
    }
  }

  private _doDelete() {
    this.file = null;
    this.imageSrc = null;
    this.onChange(null);
  }
}
