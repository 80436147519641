import * as fromRouter from '@ngrx/router-store';
import { ActionReducer, ActionReducerMap, createFeatureSelector, MetaReducer } from '@ngrx/store';
import { localStorageSync } from 'ngrx-store-localstorage';

import * as fromAuth from './auth.reducers';
import * as fromConfirm from './confirm-account.reducers';
import * as fromEvent from './event.reducer';
import * as fromParticipant from './participant.reducer';
import * as fromParticipation from './participation.reducer';
import * as fromSigner from './signer.reducer';
import * as fromSlot from './slot.reducer';
import * as fromUser from './user.reducer';

export interface AppState {
  router: fromRouter.RouterReducerState<any>;
  auth: fromAuth.AuthState;
  participant: fromParticipant.ParticipantState;
  participation: fromParticipation.ParticipationState;
  slot: fromSlot.SlotState;
  user: fromUser.UserState;
  event: fromEvent.EventState;
  signer: fromSigner.SignerState;
  confirmAccount: fromConfirm.ConfirmAccountState;
}

export const reducers: ActionReducerMap<AppState> = {
  router: fromRouter.routerReducer,
  auth: fromAuth.reducer,
  participant: fromParticipant.reducer,
  participation: fromParticipation.reducer,
  slot: fromSlot.reducer,
  user: fromUser.reducer,
  event: fromEvent.reducer,
  signer: fromSigner.reducer,
  confirmAccount: fromConfirm.reducer
};


export function localStorageSyncReducer(reducer: ActionReducer<any>): ActionReducer<any> {
  return localStorageSync({ keys: [{ auth: ['token'] }], rehydrate: true })(reducer);
}
export const metaReducers: Array<MetaReducer<any, any>> = [localStorageSyncReducer, fromAuth.clearState];

export const selectRouter = createFeatureSelector<AppState, fromRouter.RouterReducerState<any>>('router');
export const selectAuthState = createFeatureSelector<fromAuth.AuthState>('auth');
export const selectParticipantState = createFeatureSelector<fromParticipant.ParticipantState>('participant');
export const selectParticipationState = createFeatureSelector<fromParticipation.ParticipationState>('participation');
export const selectSlotState = createFeatureSelector<fromSlot.SlotState>('slot');
export const selectUserState = createFeatureSelector<fromUser.UserState>('user');
export const selectEventState = createFeatureSelector<fromEvent.EventState>('event');
export const selectSignerState = createFeatureSelector<fromSigner.SignerState>('signer');
export const selectConfirmAccountState = createFeatureSelector<fromConfirm.ConfirmAccountState>('confirmAccount');

//Route selectors
const {
  selectQueryParams,    // select the current route query params
  selectQueryParam,     // factory function to select a query param
  selectRouteParams,    // select the current route params
  selectRouteParam,     // factory function to select a route param
  selectRouteData,      // select the current route data
  selectUrl,            // select the current url
} = fromRouter.getSelectors(selectRouter);

export const selectRouteId = selectRouteParam('id');
export const selectStatus = selectQueryParam('status');
