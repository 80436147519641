import { Base, BaseDTO } from './base.model';
import { Participant, ParticipantDTO } from './participant.model';

export interface SlotDTO extends BaseDTO {
  capacity: number;
  km: number;
  incremental: number;
  note: string;
  participants: ParticipantDTO[];
}

export class Slot extends Base {
  capacity: number;
  km: number;
  incremental: number;
  note: string;
  participants: Participant[];

  constructor(dto: SlotDTO) {
    super(dto);
    if (dto) {
      this.capacity = dto.capacity;
      this.km = dto.km;
      this.incremental = dto.incremental;
      this.note = dto.note;
      if (dto.participants) {
        this.participants = dto.participants.map(participant => new Participant(participant));
        this.addLoadedRelation("participants");
      }
    }
  }

  toDTO(): SlotDTO {
    let result: SlotDTO = <SlotDTO>super.toDTO();
    result.capacity = this.capacity;
    result.km = this.km;
    result.incremental = this.incremental;
    result.note = this.note;
    result.participants = this.participants ? this.participants.map(entity => entity.toDTO()) : null;
    return result;
  }

  get occupancy(): number {
    return this.participants && this.participants.length
      ? this.participants.map(participant => participant.size).reduce((sum, size) => sum += size)
      : 0;
  }

  get freeSpace(): number {
    return this.capacity - this.occupancy > 0 ? this.capacity - this.occupancy : 0;
  }
}
