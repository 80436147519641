import { Action, createReducer, on } from '@ngrx/store';

import { EventDTO, ParticipationDTO } from '../../commons/models/participation.model';
import * as EventActions from '../actions/event.actions';

export interface EventState {
  list: EventDTO[],
  total: number,
  currentPage: number,
  perPage: number,
  order: string,
  direction: string,
  dialogId: string,
  selectedEvent: EventDTO,
  participations: ParticipationDTO[],
  participationsDialogId: string
};

const initialState: EventState = {
  list: [],
  total: 0,
  currentPage: 1,
  perPage: 25,
  order: null,
  direction: null,
  dialogId: null,
  selectedEvent: null,
  participations: null,
  participationsDialogId: null
};

const eventReducer = createReducer(
  initialState,
  on(EventActions.loadEventsCompleted, (state, { events, currentPage, total, perPage, order, direction }) => {
    return { ...state, list: events, currentPage, total, perPage, order, direction };
  }),
  on(EventActions.eventDialogOpened, (state, { dialogId }) => {
    return { ...state, dialogId };
  }),
  on(EventActions.showParticipations, (state, { event }) => {
    return { ...state, selectedEvent: event };
  }),
  on(EventActions.loadParticipationsCompleted, (state, { participations }) => {
    return { ...state, participations };
  }),
  on(EventActions.approveAttachmentCompleted, (state, { attachment }) => {
    if (attachment) {
      if (state.participations) {
        const oldParticipation = state.participations.find(p => p.id == attachment.participation_id);
        if (oldParticipation) {
          const oldParticipationIndex = state.participations.indexOf(oldParticipation);
          let newParticipation: ParticipationDTO = JSON.parse(JSON.stringify(oldParticipation));
          const oldAttachment = newParticipation.attachments.find(a => a.id == attachment.id);
          if (oldAttachment) {
            const oldAttachmentIndex = newParticipation.attachments.indexOf(oldAttachment);
            newParticipation.attachments =
              newParticipation.attachments = [
                ...newParticipation.attachments.slice(0, oldAttachmentIndex),
                attachment,
                ...newParticipation.attachments.slice(oldAttachmentIndex + 1)
              ]
          } else {
            newParticipation.attachments.push(attachment);
          }
          const participations = [
            ...state.participations.slice(0, oldParticipationIndex),
            newParticipation,
            ...state.participations.slice(oldParticipationIndex + 1)
          ]
          return { ...state, participations };
        }
      }
    }
    return { ...state };
  }),
  on(EventActions.approveCommentCompleted, (state, { participation }) => {
    if (participation) {
      if (state.participations) {
        const oldParticipation = state.participations.find(p => p.id == participation.id);
        if (oldParticipation) {
          let newParticipation: ParticipationDTO = JSON.parse(JSON.stringify(oldParticipation));
          newParticipation.comment_approved = participation.comment_approved;
          const oldParticipationIndex = state.participations.indexOf(oldParticipation);
          const participations = [
            ...state.participations.slice(0, oldParticipationIndex),
            newParticipation,
            ...state.participations.slice(oldParticipationIndex + 1)
          ]
          return { ...state, participations };
        }
      }
    }
    return { ...state };
  }),

  on(EventActions.updateCommentCompleted, (state, { participation }) => {
    if (participation) {
      if (state.participations) {
        const oldParticipation = state.participations.find(p => p.id == participation.id);
        if (oldParticipation) {
          let newParticipation: ParticipationDTO = JSON.parse(JSON.stringify(oldParticipation));
          newParticipation.comment = participation.comment;
          const oldParticipationIndex = state.participations.indexOf(oldParticipation);
          const participations = [
            ...state.participations.slice(0, oldParticipationIndex),
            newParticipation,
            ...state.participations.slice(oldParticipationIndex + 1)
          ]
          return { ...state, participations };
        }
      }
    }
    return { ...state };
  }),
  on(EventActions.participationsDialogOpened, (state, { dialogId }) => {
    return { ...state, participationsDialogId: dialogId };
  }),

);

export function reducer(state: EventState | undefined, action: Action) {
  return eventReducer(state, action);
}

