import { AbstractControl } from '@angular/forms';
import { Participant } from 'src/app/commons/models/participant.model';
import { formatDateForBackend } from 'src/app/helpers/time.utils';

import { Base, BaseDTO } from './base.model';
import { ParticipantDTO } from './participant.model';
import { Participation, ParticipationDTO } from './participation.model';

export type Role = "admin" | "user";

export const ROLES: Role[] = ["admin", "user"];

export interface UserFilters {
  search?: string;
  roles?: Role[];
  registrationStart?: Date;
  registrationEnd?: Date;
}

export interface UserDTO extends BaseDTO {
  name?: string;
  surname?: string;
  email?: string;
  password?: string;
  user_roles: Role[];
  city: string;
  province: string;
  region: string;
  nation: string;
  mobile: string;
  group_name: string;
  registration_date: string;
  participant: ParticipantDTO;
  participations: ParticipationDTO[];
}

export class User extends Base {
  email?: string;
  surname: string;
  name: string;
  role?: Role;
  city: string;
  province: string;
  region: string;
  nation: string;
  mobile: string;
  groupName: string;
  registrationDate: Date;
  password?: string;
  participant: Participant;
  participations: Participation[];

  constructor(source: UserDTO) {
    super(source);
    if (source) {
      this.name = source.name;
      this.surname = source.surname;
      this.email = source.email;
      this.city = source.city;
      this.province = source.province;
      this.region = source.region;
      this.nation = source.nation;
      this.mobile = source.mobile;
      this.groupName = source.group_name;
      if (source.registration_date) {
        this.registrationDate = new Date(source.registration_date);
      }
      if (source.user_roles) {
        this.role = source.user_roles[0];
      }
      if (source.participant) {
        this.participant = new Participant(source.participant);
        this.addLoadedRelation("participant");
      }
      if (source.participations) {
        this.participations = source.participations.map(dto => new Participation(dto));
      }
    }
  }

  toDTO(): UserDTO {
    let result: UserDTO = <UserDTO>super.toDTO();
    result.name = this.name;
    result.surname = this.surname;
    result.city = this.city;
    result.province = this.province;
    result.region = this.region;
    result.nation = this.nation;
    result.mobile = this.mobile;
    result.group_name = this.groupName;
    result.registration_date = this.registrationDate ? formatDateForBackend(this.registrationDate) : "";
    result.email = this.email;
    result.password = this.password;
    result.user_roles = [];
    result.participations = this.participations ? this.participations.map(p => p.toDTO()) : null;
    if (this.role) {
      result.user_roles.push(this.role);
    }
    return result;
  }

  static fromFormGroup(formGroup: AbstractControl, original?: User) {
    const formModel = formGroup.value;
    let user: User = new User(null);
    user.name = formModel.name;
    user.surname = formModel.surname;
    user.email = formModel.email;
    user.city = formModel.city;
    user.province = formModel.province;
    user.nation = formModel.nation;
    user.region = formModel.region;
    user.mobile = formModel.mobile;
    user.groupName = formModel.groupName;
    user.registrationDate = formModel.registrationDate;
    user.password = formModel.password;
    user.role = formModel.role;
    if (original) {
      user.id = original.id;
    }
    return user;
  }

  get fullName(): string {
    return `${this.name} ${this.surname}`;
  }

  get admin(): boolean {
    return this.role == 'admin';
  }
}
