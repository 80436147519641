import { UserParticipationsComponent } from './../../modules/home/users/user-participations/user-participations.component';
import { ParticipationService } from 'src/app/commons/services/backend/laravel-participation.service';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { saveAs } from 'file-saver';
import { of } from 'rxjs';
import { catchError, flatMap, take, map, mergeMap, switchMap, tap, withLatestFrom } from 'rxjs/operators';

import { AlertService } from '../../commons/services/alert.service';
import { LaravelUserService } from '../../commons/services/backend/laravel-user.service';
import { UserEditComponent } from '../../modules/home/users/user-edit/user-edit.component';
import * as UserActions from '../actions/user.actions';
import * as UserSelectors from '../selectors/user.selectors';
import * as AuthSelectors from 'src/app/store/selectors/auth.selectors';
import { AppState } from '../reducers';
import { getUserDialogId, getUsersTableState } from '../selectors/user.selectors';
import { PasswordChangeComponent } from 'src/app/modules/home/users/user-edit/password-change/password-change.component';
import { UserDTO } from 'src/app/commons/models/user.model';

@Injectable()
export class UserEffects {

  error$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.saveUserFailed, UserActions.exportUsersFailed),
      tap(({ error }) => {
        if (error) {
          this.alertService.showErrorMessage('Errore', error);
        }
      })
    ), { dispatch: false }
  );

  loadUsers$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.loadUsers),
      switchMap(({ page, perPage, order, direction, filters }) => {
        return this.userService.list(page, perPage, order, direction, filters)
          .pipe(
            map(result =>
              UserActions.loadUsersCompleted({ users: result.data, currentPage: page, total: result.total, perPage, order, direction, filters })
            ),
            catchError(error => {
              return of(UserActions.loadUsersFailed({ error }))
            })
          )
      })
    )
  );

  changePage = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.changePage),
      withLatestFrom(this.store$.select(getUsersTableState)),
      map(([{ page, size }, { total, currentPage, perPage, direction, order, filters }]) => UserActions.loadUsers({ page: page, perPage: size, order, direction, filters }))
    )
  );

  changeSort = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.changeSort),
      withLatestFrom(this.store$.select(getUsersTableState)),
      map(([action, { total, currentPage, perPage, direction, order, filters }]) => UserActions.loadUsers({ page: currentPage, perPage: perPage, order: action.order, direction: action.direction, filters }))
    )
  );

  changeFilters = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.changeFilters),
      withLatestFrom(this.store$.select(getUsersTableState)),
      map(([{ filters }, { total, currentPage, perPage, direction, order }]) => UserActions.loadUsers({ page: currentPage, perPage: perPage, order, direction, filters }))
    )
  );

  addUser$ = createEffect(() => this.actions$.pipe(
    ofType(UserActions.addUser),
    map(() => UserActions.editUser({ user: null }))
  ))

  editUser$ = createEffect(() => this.actions$.pipe(
    ofType(UserActions.editUser),
    map(({ user }) => {
      let dialogRef = this.dialog.open(UserEditComponent, {
        data: {
          user
        }
      });
      return UserActions.userDialogOpened({ dialogId: dialogRef.id });
    }))
  );

  saveUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.saveUser),
      mergeMap(({ user }) =>
        this.userService.upsert(user.toDTO())
          .pipe(
            map(result =>
              UserActions.saveUserCompleted({ user: result })
            ),
            catchError(error => of(UserActions.saveUserFailed({ error })))
          )
      )
    )
  );

  onSaveCompleted$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.saveUserCompleted),
      map(action => action.user),
      tap(user => this.alertService.showConfirmMessage(`Utente ${user.name} ${user.surname} salvato con successo`)),
      map(() => UserActions.closeUserDialog())
    )
  );

  changeUserPassword$ = createEffect(() => this.actions$.pipe(
    ofType(UserActions.changeUserPassword),
    map(({ user }) => {
      let dialogRef = this.dialog.open(PasswordChangeComponent, {
        data: {
          user
        }
      });
      return UserActions.changePasswordDialogOpen({ dialogId: dialogRef.id });
    }))
  );

  updatePassword$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.updatePassword),
      flatMap(({ newPassword, user }) => {
        if (user) {
          return of({ newPassword, user });
        } else {
          return this.store$.select(AuthSelectors.getCurrentUser).pipe(take(1), map(user => {
            return { newPassword, user }
          }));
        }
      }),
      switchMap(({ newPassword, user }) => {
        let newUser: UserDTO = JSON.parse(JSON.stringify(user));
        newUser.password = newPassword;
        return this.userService.upsert(newUser).pipe(
          map(user =>
            UserActions.updatePasswordCompleted({ user })
          ),
          catchError(error => {
            return of(UserActions.updatePasswordFailed({ error }))
          })
        )
      })
    )
  );

  onUpdatePasswordCompleted$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.updatePasswordCompleted),
      map(action => action.user),
      tap(user => this.alertService.showConfirmMessage(`Password per ${user.email} aggiornata con successo`)),
      map(() => UserActions.closeChangePasswordDialog())));

  closeChangePasswordDialog = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.closeChangePasswordDialog),
      withLatestFrom(this.store$.select(UserSelectors.getChangePasswordDialogId)),
      tap(([_, dialogId]) => {
        if (dialogId) {
          this.dialog.getDialogById(dialogId).close();
        }
      })
    ), { dispatch: false }
  );


  closeDialog = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.closeUserDialog),
      withLatestFrom(this.store$.select(getUserDialogId)),
      tap(([_, dialogId]) => {
        if (dialogId) {
          this.dialog.getDialogById(dialogId).close();
        }

      })
    ), { dispatch: false }
  );

  reloadAfterSave = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.saveUserCompleted, UserActions.deleteUserCompleted),
      withLatestFrom(this.store$.select(getUsersTableState)),
      map(([_, { total, currentPage, perPage, direction, order, filters }]) => UserActions.loadUsers({ page: currentPage, perPage, order, direction, filters }))
    )
  );

  delete$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.deleteUser),
      map(action => action.user),
      mergeMap((user) =>
        this.alertService.showConfirmDialog('Conferma eliminazione', `Sei sicuro di voler eliminare l'utente ${user.fullName}?`)
          .pipe(
            mergeMap((confirm) => {
              return confirm ?
                this.userService.delete(user.id)
                  .pipe(
                    map(() => UserActions.deleteUserCompleted({ user: user.toDTO() })),
                    catchError(error => of(UserActions.deleteUserFailed({ error })))
                  )
                : of(UserActions.deleteUserCancelled());
            })
          )
      )
    )
  );

  onDeleteCompleted$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.deleteUserCompleted),
      map(action => action.user),
      tap(user => this.alertService.showConfirmMessage(`Utente ${user.name} ${user.surname} eliminato con successo`)),
      map(() => UserActions.closeUserDialog())
    )
  );

  showParticipations$ = createEffect(() => this.actions$.pipe(
    ofType(UserActions.showParticipations),
    map(({ user }) => {
      let dialogRef = this.dialog.open(UserParticipationsComponent, {
        width: "90%",
        data: {
          user
        }
      });
      return UserActions.participationsDialogOpened({ dialogId: dialogRef.id });
    }))
  );

  loadParticipations$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.loadParticipations),
      switchMap(({ user }) =>
        this.participationService.getByUser(user.id)
          .pipe(
            map(participations =>
              UserActions.loadParticipationsCompleted({ participations })
            ),
            catchError(error => of(UserActions.loadParticipationsFailed({ error })))
          )
      )
    )
  );

  closeParticipationDialog = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.closeParticipationsDialog),
      withLatestFrom(this.store$.select(UserSelectors.getParticipationsDialogId)),
      tap(([_, dialogId]) => {
        if (dialogId) {
          this.dialog.getDialogById(dialogId).close();
        }
      })
    ), { dispatch: false }
  );

  export$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.exportUsers),
      withLatestFrom(this.store$.select(UserSelectors.getFilters)),
      switchMap(([_, filters]) =>
        this.userService.export(filters)
          .pipe(
            map(file =>
              UserActions.exportUsersCompleted({ file })
            ),
            catchError(error => of(UserActions.exportUsersFailed({ error })))
          )
      )
    )
  );

  exportCompleted$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.exportUsersCompleted),
      tap(({ file }) => {
        saveAs(file, 'utenti.csv');
      })
    ), { dispatch: false }
  );

  constructor(
    private actions$: Actions,
    private store$: Store<AppState>,
    private userService: LaravelUserService,
    private dialog: MatDialog,
    private alertService: AlertService,
    private participationService: ParticipationService,
  ) { }
}
