<header mat-dialog-title class="u-flex-full">
  <span>
    <span *ngIf="user">Modifica {{user.fullName}}</span>
    <span *ngIf="!user">Nuovo utente</span>
  </span>
  <span *ngIf="user">
    <button mat-icon-button [mat-menu-trigger-for]="actionMenu">
      <mat-icon>more_vert</mat-icon>
    </button>
    <mat-menu x-position="before" overlapTrigger="false" #actionMenu="matMenu">
      <button mat-menu-item (click)="changePassword()">
        <mat-icon>vpn_key</mat-icon>
        Cambia Password
      </button>
      <button *showFor="['admin']" mat-menu-item (click)="deleteUser()">
        <mat-icon>delete</mat-icon>
        Elimina
      </button>
    </mat-menu>
  </span>
</header>
<mat-dialog-content>
  <form [formGroup]="userForm" novalidate>
    <div class="u-flex-full">
      <mat-form-field class="u-full-width">
        <input matInput placeholder="Nome" formControlName="name" type="text" required>
        <mat-error>Obbligatorio</mat-error>
      </mat-form-field>
      <mat-form-field class="u-full-width">
        <input matInput placeholder="Cognome" formControlName="surname" type="text" required>
        <mat-error>Obbligatorio</mat-error>
      </mat-form-field>
      <mat-form-field *showFor="['admin']">
        <mat-select placeholder="Ruolo" formControlName="role" required>
          <mat-option [value]="role" *ngFor="let role of roles">
            {{role | userRole}}
          </mat-option>
        </mat-select>
        <mat-error>Obbligatorio</mat-error>
      </mat-form-field>
    </div>
    <div class="u-flex-full">
      <mat-form-field class="u-full-width">
        <input matInput placeholder="E-mail" formControlName="email" type="email" required>
        <mat-error>Obbligatorio</mat-error>
      </mat-form-field>
      <mat-form-field class="u-full-width">
        <input matInput placeholder="Cellulare" formControlName="mobile" type="tel">
      </mat-form-field>
      <mat-form-field class="u-full-width">
        <input matInput placeholder="Data di registrazione" (click)="datePicker.open()" [matDatepicker]="datePicker"
          formControlName="registrationDate">
        <mat-datepicker-toggle matSuffix [for]="datePicker"></mat-datepicker-toggle>
        <button matPrefix mat-button type="button" *ngIf="userForm.value['registrationDate']" mat-icon-button
          aria-label="Clear" (click)="userForm.patchValue({registrationDate: ''})">
          <mat-icon>close</mat-icon>
        </button>
        <mat-datepicker #datePicker></mat-datepicker>
      </mat-form-field>
    </div>
    <div class="u-flex-full">
      <mat-form-field class="u-full-width">
        <input matInput placeholder="Città" formControlName="city" type="text">
      </mat-form-field>
      <mat-form-field class="u-full-width">
        <input matInput placeholder="Regione" formControlName="region" type="text">
      </mat-form-field>
      <mat-form-field class="u-full-width">
        <input matInput placeholder="Provincia" formControlName="province" type="text">
      </mat-form-field>
      <mat-form-field class="u-full-width">
        <input matInput placeholder="Nazione" formControlName="nation" type="text">
      </mat-form-field>
    </div>
    <mat-form-field class="u-full-width">
      <input matInput placeholder="Organizzazione" formControlName="groupName" type="text">
    </mat-form-field>
    <div *ngIf="!user" class="u-full-width">
      <span class="u-flex-full">
        <mat-form-field>
          <input type="password" placeholder="Password" matInput formControlName="password" required>
        </mat-form-field>
        <mat-form-field class="u-full-width">
          <input type="password" placeholder="Conferma password" matInput formControlName="confirmPass">
        </mat-form-field>
      </span>
      <mat-error *ngIf="userForm.hasError('notEquivalent')" class="u-full-width" style="text-align: center;">Le password
        inserite non coincidono</mat-error>
    </div>
  </form>

</mat-dialog-content>
<mat-dialog-actions class="u-flex-full">
  <span>
    <button mat-raised-button color="basic" type="reset" (click)="revert()"
      [disabled]="userForm.pristine">Reset</button>
    <button mat-raised-button color="primary" (click)="save()"
      [disabled]="userForm.pristine || !userForm.valid">Salva</button>
    <span class="fill-remaining"></span>
  </span>
  <button mat-raised-button color="basic" type="button" (click)="close()">Chiudi</button>
</mat-dialog-actions>
