export class BaseDTO {
    id?: number;
    created_at?: Date;
    updated_at?: Date;
    deleted_at?: Date;
    includes?: string[];
}

export class Base {
    id?: number;
    createdAt?: Date;
    updatedAt?: Date;
    deletedAt?: Date;
    loadedRelations?: string[];

    constructor(source: BaseDTO, loadedRelations?: string[]) {
        if (source) {
            this.id = source.id;

            this.createdAt = source.created_at ? new Date(source.created_at) : null;
            this.updatedAt = source.updated_at ? new Date(source.updated_at) : null;
            this.deletedAt = source.deleted_at ? new Date(source.deleted_at) : null;
        }

        if (loadedRelations) {
            this.loadedRelations = loadedRelations;
        }
    }

    get archived(): boolean {
        return this.deletedAt != null;
    }

    public toDTO(): BaseDTO {
        return {
            id: this.id,
            includes: this.loadedRelations
        };
    }

    public static compare(b1: Base, b2: Base): boolean {
        return b1 && b2 ? b1.id === b2.id : b1 === b2;
    }

    public addLoadedRelations(names: string[]) {
        if (names) {
            names.forEach(name => this.addLoadedRelation(name));
        }
    }

    public addLoadedRelation(name: string) {
        if (!this.loadedRelations) {
            this.loadedRelations = [];
        }
        if (this.loadedRelations.indexOf(name) == -1) {
            this.loadedRelations.push(name);
        }
    }

    public isRelationLoaded(name: string) {
        return this.loadedRelations
            ? this.loadedRelations.indexOf(name) != -1
            : false;
    }
}
