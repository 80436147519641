import * as dayjs from 'dayjs';

const BACKEND_DATE_FORMAT = "YYYY-MM-DD";
const BACKEND_DATE_FORMAT_WITH_TIME = "YYYY-MM-DD HH:mm:ss";

export function formatDateForBackend(date: Date, includeTime: boolean = false) {
  if (date) {
    return dayjs(date).format(
      includeTime ? BACKEND_DATE_FORMAT_WITH_TIME : BACKEND_DATE_FORMAT
    );
  }
  return null;
}
