<mat-card class="mb-20">
  <form [formGroup]="filtersForm" novalidate>
    <div class="u-flex-full" style="align-items: baseline">
      <mat-form-field floatLabel="always" class="u-full-width">
        <mat-label>Ricerca libera</mat-label>
        <input type="text" matInput formControlName="search">
      </mat-form-field>
      <mat-form-field>
        <mat-label>Data registrazione - Da</mat-label>
        <input matInput [matDatepicker]="fromDatePicker" formControlName="registrationStart" />
        <mat-datepicker-toggle matSuffix [for]="fromDatePicker"></mat-datepicker-toggle>
        <mat-datepicker #fromDatePicker></mat-datepicker>
      </mat-form-field>
      <mat-form-field>
        <mat-label>Data registrazione - A</mat-label>
        <input matInput [matDatepicker]="toDatePicker" formControlName="registrationEnd" />
        <mat-datepicker-toggle matSuffix [for]="toDatePicker"></mat-datepicker-toggle>
        <mat-datepicker #toDatePicker></mat-datepicker>
      </mat-form-field>
      <mat-form-field>
        <mat-select formControlName="types" multiple>
          <mat-select-trigger>
            <span *ngIf="filtersForm.value.types?.length == types.length">Tutti</span>
            <span *ngIf="filtersForm.value.types?.length < types.length">
              {{(filtersForm.value.types ? filtersForm.value.types[0] : '') }}
              <span *ngIf="filtersForm.value.types?.length > 1" class="additional-selection">
                (+{{filtersForm.value.types.length - 1}}
                {{filtersForm.value.types?.length === 2 ? 'altro' : 'altri'}})
              </span>
            </span>
          </mat-select-trigger>
          <mat-option *ngFor="let type of types" [value]="type" [ngClass]="type">
            {{type}}</mat-option>
        </mat-select>
        <mat-hint>Tipologia</mat-hint>
      </mat-form-field>
      <span *ngIf="showSlotFilters">
        <mat-checkbox formControlName="withSlot">Includi assegnati</mat-checkbox>
        <mat-checkbox formControlName="withoutSlot">Includi non assegnati</mat-checkbox>
      </span>
      <span class="u-flex-full">
        <button mat-raised-button [disabled]="filtersForm.pristine" color="primary"
          (click)="applyFilters()">Filtra</button>
        <button mat-icon-button *ngIf="!filtersForm.pristine" (click)="resetFilters()" matTooltip="Reset">
          <mat-icon>replay</mat-icon>
        </button>
      </span>
    </div>
  </form>
</mat-card>
