import { AbstractControl } from '@angular/forms';
import { formatDateForBackend } from 'src/app/helpers/time.utils';

import { Base, BaseDTO } from './base.model';
import { Slot, SlotDTO } from './slot.model';
import { User, UserDTO } from './user.model';

export type TransportType = "Auto" | "Pullman" | "Treno" | "Altro";
export type ParticipantType = "Singolo" | "Gruppo" | "Scuola" | "Ente locale";
export type EventName = "marcia_2020" | "marcia_2021" | "iocero"

export interface ParticipantFilters {
  excludeWithSlot?: boolean;
  excludeWithoutSlot?: boolean;
  excludes?: number[];
  registrationStart?: Date;
  registrationEnd?: Date;
  types?: ParticipantType[];
  search?: string;
  eventName?: EventName;
}

export interface ParticipantDTO extends BaseDTO {
  name: string;
  surname: string;
  city: string;
  province: string;
  region: string;
  nation: string;
  mobile: string;
  email: string;
  group: boolean;
  school: boolean;
  local_authority: boolean;
  group_name: string;
  group_size: number;
  transport: TransportType;
  registration_date: string;
  banner: boolean;
  note: string;
  slot?: SlotDTO;
  user: UserDTO;
  event_name?: string;
}

export class Participant extends Base {
  name: string;
  surname: string;
  city: string;
  province: string;
  region: string;
  nation: string;
  mobile: string;
  email: string;
  group: boolean;
  school: boolean;
  localAuthority: boolean;
  groupName: string;
  groupSize: number;
  transport: TransportType;
  registrationDate: Date;
  banner: boolean;
  note: string;
  slot?: Slot;
  user: User;
  eventName?: string;

  constructor(dto: ParticipantDTO) {
    super(dto);
    if (dto) {
      this.name = dto.name;
      this.surname = dto.surname;
      this.city = dto.city;
      this.province = dto.province;
      this.region = dto.region;
      this.nation = dto.nation;
      this.mobile = dto.mobile;
      this.email = dto.email;
      this.group = dto.group;
      this.school = dto.school;
      this.localAuthority = dto.local_authority;
      this.groupName = dto.group_name;
      this.groupSize = dto.group_size;
      this.transport = dto.transport;
      this.banner = dto.banner;
      this.note = dto.note;
      this.eventName = dto.event_name;
      if (dto.registration_date) {
        this.registrationDate = new Date(dto.registration_date);
      }
      if (dto.slot) {
        this.slot = new Slot(dto.slot);
        this.addLoadedRelation("slot");
      }
      if (dto.user) {
        this.user = new User(dto.user);
        this.addLoadedRelation("user");
      }
    }
  }

  toDTO(): ParticipantDTO {
    let result: ParticipantDTO = <ParticipantDTO>super.toDTO();
    result.name = this.name;
    result.surname = this.surname;
    result.city = this.city;
    result.province = this.province;
    result.region = this.region;
    result.nation = this.nation;
    result.mobile = this.mobile;
    result.email = this.email;
    result.group = this.group;
    result.school = this.school;
    result.local_authority = this.localAuthority;
    result.group_name = this.groupName;
    result.group_size = this.groupSize;
    result.transport = this.transport;
    result.banner = this.banner;
    result.note = this.note;
    result.registration_date = this.registrationDate ? formatDateForBackend(this.registrationDate) : "";
    result.slot = this.slot ? this.slot.toDTO() : null;
    result.user = this.user ? this.user.toDTO() : null;
    result.event_name = this.eventName;
    return result;
  }

  static fromFormGroup(formGroup: AbstractControl, original?: Participant) {
    const formModel = formGroup.value;
    let participant: Participant = new Participant(null);
    participant.name = formModel.name;
    participant.surname = formModel.surname;
    participant.city = formModel.city;
    participant.province = formModel.province;
    participant.nation = formModel.nation;
    participant.region = formModel.region;
    participant.mobile = formModel.mobile;
    participant.email = formModel.email;

    participant.group = <ParticipantType>formModel.type == "Gruppo";
    participant.school = <ParticipantType>formModel.type == "Scuola";
    participant.localAuthority = <ParticipantType>formModel.type == "Ente locale";

    participant.groupName = formModel.groupName;
    participant.groupSize = formModel.groupSize;
    participant.transport = formModel.transport;
    participant.registrationDate = formModel.registrationDate;
    participant.banner = formModel.banner;
    participant.note = formModel.note;

    participant.eventName = formModel.eventName;

    if (original) {
      participant.id = original.id;
    }
    return participant;
  }

  get fullName(): string {
    return `${this.name} ${this.surname}`;
  }

  get private(): boolean {
    return !this.group && !this.school && !this.localAuthority;
  }

  get type(): ParticipantType {
    if (this.private) return "Singolo";
    if (this.school) return "Scuola";
    if (this.localAuthority) return "Ente locale";
    return "Gruppo";
  }

  get size(): number {
    return this.private ? 1 : this.groupSize;
  }

  get hasUser(): boolean {
    return !!this.user;
  }
}
