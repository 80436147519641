import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { MaterialModule } from './../material/material.module';
import { ConfirmDialogComponent } from './confirm-dialog/confirm-dialog.component';
import { ImgFallbackDirective } from './directives/img-fallback.directive';
import { ShowForDirective } from './directives/show-for.directive';
import { ImageUploadComponent } from './image-upload/image-upload.component';
import { ParticipantFiltersComponent } from './participant-filters/participant-filters.component';
import { ParticipantListComponent } from './participant-list/participant-list.component';
import { ParticipantTypeComponent } from './participant-type/participant-type.component';
import { RolePipe } from './pipes/role.pipe';

@NgModule({
  imports: [
    CommonModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule
  ],
  exports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    MaterialModule,
    ParticipantListComponent,
    ParticipantFiltersComponent,
    ParticipantTypeComponent,
    RolePipe,
    ShowForDirective,
    ImgFallbackDirective,
    ImageUploadComponent
  ],
  declarations: [
    ConfirmDialogComponent,
    ParticipantListComponent,
    ParticipantFiltersComponent,
    ParticipantTypeComponent,
    RolePipe,
    ShowForDirective,
    ImgFallbackDirective,
    ImageUploadComponent
  ],
  entryComponents: [ConfirmDialogComponent]
})
export class SharedModule { }
