import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ListResultDTO } from 'src/app/helpers/listResult.interface';
import { formatDateForBackend } from 'src/app/helpers/time.utils';
import { environment } from 'src/environments/environment';

import { UserDTO } from '../../models/user.model';
import { UserFilters } from './../../models/user.model';

export interface LoginSuccessDTO {
  access_token: string;
  user: UserDTO;
  expires_at: Date,
  token_type: string
}

@Injectable({
  providedIn: "root"
})
export class LaravelUserService {
  constructor(private httpClient: HttpClient) { }

  private get ROUTES() {
    return {
      currentUser: environment.baseUrl + "/api/currentUser",
      login: environment.baseUrl + "/api/login",
      logout: environment.baseUrl + "/api/logout",
      list: environment.baseUrl + "/api/users",
      store: environment.baseUrl + "/api/user",
      delete: environment.baseUrl + "/api/user",
      export: environment.baseUrl + "/api/users/export",
    };
  }

  public list(page: number,
    per_page: number,
    order: string,
    direction: string,
    filters: UserFilters): Observable<ListResultDTO<UserDTO>> {
    let params = {};
    if (order) params["order"] = "" + order;
    if (direction) params["direction"] = "" + direction;
    this._addFiltersToParams(params, filters);
    if (per_page) {
      params["per_page"] = "" + per_page;
      if (page) params["page"] = "" + page;
      return this.httpClient.get<ListResultDTO<UserDTO>>(this.ROUTES.list, {
        params: new HttpParams({ fromObject: params })
      });
    } else {
      return this.httpClient.get<UserDTO[]>(this.ROUTES.list, {
        params: new HttpParams({ fromObject: params })
      }).pipe(
        map(results => {
          return {
            data: results,
            total: results.length
          };
        })
      );
    }
  }

  public getCurrentUser(): Observable<UserDTO> {
    return this.httpClient.get<UserDTO>(this.ROUTES.currentUser);
  }


  public upsert(user: UserDTO): Observable<UserDTO> {
    return user.id
      ? this.httpClient.put<UserDTO>(`${this.ROUTES.store}`, user)
      : this.httpClient.post<UserDTO>(`${this.ROUTES.store}`, user)
  }

  public delete(id: number): Observable<any> {
    return this.httpClient.delete<any>(`${this.ROUTES.delete}`, { params: new HttpParams({ fromObject: { id: "" + id } }) });
  }

  public login(email: string, password: string): Observable<LoginSuccessDTO> {
    return this.httpClient.post<LoginSuccessDTO>(this.ROUTES.login, { email, password });
  }

  public logout(): Observable<any> {
    return this.httpClient.post<any>(this.ROUTES.logout, {});
  }

  public export(filters?: UserFilters): Observable<Blob> {
    let params = {};
    this._addFiltersToParams(params, filters);
    return this.httpClient.get(`${this.ROUTES.export}`, { responseType: "blob", params: new HttpParams({ fromObject: params }) });
  }


  private _addFiltersToParams(params: any, filters: UserFilters) {
    if (filters) {
      if (filters.search) params["search"] = "" + filters.search;
      if (filters.roles) params["roles[]"] = filters.roles;
      if (filters.registrationStart) params["from"] = formatDateForBackend(filters.registrationStart);
      if (filters.registrationEnd) params["to"] = formatDateForBackend(filters.registrationEnd);
    }
  }

}
