import { createAction, props } from '@ngrx/store';
import { UserDTO } from 'src/app/commons/models/user.model';
import { ParticipationTotalsDTO } from 'src/app/commons/services/backend/laravel-participation.service';

import { AttachmentDTO, EventDTO, ParticipationDTO, ParticipationFilters } from './../../commons/models/participation.model';


export const loadParticipation = createAction('[Participations] Load participation for event', props<{ eventId: number, userId: number }>());
export const loadParticipationCompleted = createAction('[Participations] Load participation for event Completed', props<{ eventParticipation: ParticipationDTO }>());
export const loadParticipationFailed = createAction('[Participations] Load participation for event Failed', props<{ error: any }>());

export const loadEvent = createAction('[Participations] Load event', props<{ id: number }>());
export const loadEventCompleted = createAction('[Participations] Load event Completed', props<{ event: EventDTO }>());
export const loadEventFailed = createAction('[Participations] Load event Failed', props<{ error: any }>());

export const loadAttachments = createAction('[Participations] Load participation attachments', props<{ participationId: number }>());
export const loadAttachmentsCompleted = createAction('[Participations] Load participation attachments Completed', props<{ attachments: AttachmentDTO[] }>());
export const loadAttachmentsFailed = createAction('[Participations] Load participation attachments Failed', props<{ error: any }>());

export const showEvent = createAction('[Participations] Show event', props<{ event: EventDTO, participation: ParticipationDTO }>());
export const loadEvents = createAction('[Participations] Load available events');
export const loadEventsCompleted = createAction('[Participations] Load events Completed', props<{ events: EventDTO[] }>());
export const loadEventsFailed = createAction('[Participations] Load events Failed', props<{ error: any }>());

export const loadUserParticipations = createAction('[Participations] Load user participations', props<{ user: UserDTO }>());
export const loadUserParticipationsCompleted = createAction('[Participations] Load user participations Completed', props<{ participations: ParticipationDTO[] }>());
export const loadUserParticipationsFailed = createAction('[Participations] Load user participations Failed', props<{ error: any }>());

export const loadParticipations = createAction('[Participations] Load participations', props<{ page?: number, perPage?: number, order?: string, direction?: string, filters?: ParticipationFilters }>());
export const loadParticipationsCompleted = createAction('[Participations] Load participations Completed', props<{ participations: ParticipationDTO[], currentPage: number, total: number, perPage: number, order?: string, direction?: string, filters?: ParticipationFilters }>());
export const loadParticipationsFailed = createAction('[Participations] Load participations Failed', props<{ error: any }>());

export const changePage = createAction('[Participations] Change page', props<{ page: number, size: number }>());
export const changeSort = createAction('[Participations] Change sort', props<{ order: string, direction: string }>());
export const changeFilters = createAction('[Participations] Change filters', props<{ filters: ParticipationFilters }>());

export const updateComment = createAction('[Participations] Update comment', props<{ participationId: number, comment: string }>());
export const updateCommentCompleted = createAction('[Participations] Update comment completed', props<{ participation: ParticipationDTO }>());
export const updateCommentFailed = createAction('[Participations] Update comment failed', props<{ error: any }>());

export const saveParticipation = createAction('[Participations] Save participation', props<{ participation: ParticipationDTO }>());
export const saveParticipationCompleted = createAction('[Participations] Save participation completed', props<{ participation: ParticipationDTO }>());
export const saveParticipationFailed = createAction('[Participations] Save participation failed', props<{ error: any }>());

export const deleteParticipation = createAction('[Participations] Delete participation', props<{ participation: ParticipationDTO }>());
export const deleteParticipationCompleted = createAction('[Participations] Delete participation completed', props<{ participation: ParticipationDTO }>());
export const deleteParticipationCancelled = createAction('[Participations] Delete participation cancelled');
export const deleteParticipationFailed = createAction('[Participations] Delete participation failed', props<{ error: any }>());

export const editAttachments = createAction('[Participations] Edit attachments', props<{ participation: ParticipationDTO }>());
export const closeAttachmentsDialog = createAction('[Participations] Close attachments dialog');

export const confirmParticipation = createAction('[Participations] Confirm participation', props<{ event: EventDTO, user: UserDTO }>());

export const addImage = createAction('[Participations] Add image', props<{ participationId: number, file: File }>());
export const addImageCompleted = createAction('[Participations] Add image completed', props<{ attachment: AttachmentDTO, participationId: number }>());
export const addImageFailed = createAction('[Participations] Add image failed', props<{ error: any }>());

export const addAttachment = createAction('[Participations] Add attachment', props<{ participationId: number, file: File }>());
export const addAttachmentCompleted = createAction('[Participations] Add attachment completed', props<{ attachment: AttachmentDTO, participationId: number }>());
export const addAttachmentFailed = createAction('[Participations] Add attachment failed', props<{ error: any }>());

export const downloadAttachment = createAction('[Participations] Download attachment', props<{ attachment: AttachmentDTO }>());

export const removeAttachment = createAction('[Participations] Remove attachment', props<{ attachment: AttachmentDTO }>());
export const removeAttachmentCompleted = createAction('[Participations] Remove attachment completed', props<{ attachment: AttachmentDTO }>());
export const removeAttachmentCancelled = createAction('[Participations] Remove attachment cancelled');
export const removeAttachmentFailed = createAction('[Participations] Remove participation failed', props<{ error: any }>());

export const loadTotals = createAction('[Participations] Load totals');
export const loadTotalsCompleted = createAction('[Participations] Load totals completed', props<{ totals: ParticipationTotalsDTO }>());
export const loadTotalsFailed = createAction('[Participations] Load totals failed', props<{ error: any }>());
