import { createAction, props } from '@ngrx/store';

import { SignerDTO, SignerFilters } from '../../commons/models/signer.model';

export const loadSigners = createAction('[Signers] Load signers', props<{ page: number, perPage: number, order?: string, direction?: string, filters?: SignerFilters }>());
export const loadSignersCompleted = createAction('[Signers] Load signers Completed', props<{ signers: SignerDTO[], currentPage: number, total: number, perPage: number, order?: string, direction?: string, filters?: SignerFilters }>());
export const loadSignersFailed = createAction('[Signers] Load signers Failed', props<{ error: any }>());

export const changePage = createAction('[Signers] Change page', props<{ page: number, size: number }>());
export const changeSort = createAction('[Signers] Change sort', props<{ order: string, direction: string }>());
export const changeFilters = createAction('[Signers] Change filters', props<{ filters: SignerFilters }>());

export const addSigner = createAction('[Signers] Add signer');
export const editSigner = createAction('[Signers] Edit signer', props<{ signer: SignerDTO }>());
export const signerDialogOpened = createAction('[Signers] Detail dialog opened', props<{ dialogId: string }>());
export const closeSignerDialog = createAction('[Signers] Close detail dialog');

export const saveSigner = createAction('[Signers] Save signer', props<{ signer: SignerDTO }>());
export const saveSignerCompleted = createAction('[Signers] Save signer completed', props<{ signer: SignerDTO }>());
export const saveSignerFailed = createAction('[Signers] Save signer failed', props<{ error: any }>());

export const deleteSigner = createAction('[Signers] Delete signer', props<{ signer: SignerDTO }>());
export const deleteSignerCompleted = createAction('[Signers] Delete signer completed', props<{ signer: SignerDTO }>());
export const deleteSignerCancelled = createAction('[Signers] Delete signer cancelled');
export const deleteSignerFailed = createAction('[Signers] Delete signer failed', props<{ error: any }>());

export const exportSigners = createAction('[Signers] Export signers');
export const exportSignersCompleted = createAction('[Signers] Export signers Completed', props<{ file: Blob }>());
export const exportSignersFailed = createAction('[Signers] Export signers Failed', props<{ error: any }>());
