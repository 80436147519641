import { Component, Input } from '@angular/core';

import { ParticipantType } from './../../../commons/models/participant.model';


@Component({
  selector: "thc-participant-type",
  templateUrl: "./participant-type.component.html",
  styleUrls: ["./participant-type.component.scss"]
})
export class ParticipantTypeComponent {

  @Input()
  type: ParticipantType;

  get cssClass(): string {
    return "badge--" + this.type.toLowerCase().replace(/\s/g, '_');
  }
}
