import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ListResultDTO } from 'src/app/helpers/listResult.interface';
import { environment } from 'src/environments/environment';

import { EventDTO } from '../../models/participation.model';
import { Event } from './../../models/participation.model';

@Injectable({
  providedIn: "root"
})
export class LaravelEventService {
  constructor(private httpClient: HttpClient) { }

  private get ROUTES() {
    return {
      show: environment.baseUrl + "/api/event",
      list: environment.baseUrl + "/api/events",
      store: environment.baseUrl + "/api/event",
      delete: environment.baseUrl + "/api/event",
      export_participations: environment.baseUrl + "/api/event/export_participations",
    };
  }

  public getById(id: number, include?: string[]): Observable<EventDTO> {
    let params = {
      id: "" + id
    };
    if (include) {
      params["include[]"] = include;
    }
    return this.httpClient.get<EventDTO>(`${this.ROUTES.show}`, { params: new HttpParams({ fromObject: params }) });
  }

  public listAll(): Observable<EventDTO[]> {
    return this.list(1, 999999, 'date', 'ASC').pipe(map(result => result.data));
  }

  public list(page: number,
    per_page: number,
    order: string,
    direction: string
  ): Observable<ListResultDTO<EventDTO>> {
    let params = {};
    if (order) params["order"] = "" + order;
    if (direction) params["direction"] = "" + direction;
    if (per_page) params["per_page"] = "" + per_page;
    if (page) params["page"] = "" + page;
    return this.httpClient.get<ListResultDTO<EventDTO>>(this.ROUTES.list, {
      params: new HttpParams({ fromObject: params })
    });
  }

  public upsert(event: EventDTO): Observable<EventDTO> {
    return this.httpClient.post<EventDTO>(`${this.ROUTES.store}`, Event.DTOtoFormData(event))
  }

  public delete(id: number): Observable<any> {
    return this.httpClient.delete<any>(`${this.ROUTES.delete}`, { params: new HttpParams({ fromObject: { id: "" + id } }) });
  }

  public exportParticipations(id: number): Observable<Blob> {
    return this.httpClient.get(`${this.ROUTES.export_participations}`, { responseType: "blob", params: new HttpParams({ fromObject: { id: "" + id } }) });
  }
}
