import { FormControl, ValidationErrors } from '@angular/forms';

import { BaseDTO } from '../commons/models/base.model';
import { Base } from './../commons/models/base.model';

export function removeFromArray<T>(source: Array<T>, element: T) {
  if (source.includes(element)) {
    source.splice(source.indexOf(element), 1);
  }
}

export function autocompleteSelectionValidator(control: FormControl): ValidationErrors | null {
  let selection = control.value;
  if (typeof selection === "string") {
    return { invalid: true };
  }
  return null;
}

export function replaceInArray(
  array: Array<Base | BaseDTO>,
  newItem: Base | BaseDTO,
  addIfNotExists: boolean = true
): Array<Base | BaseDTO> {
  if (array) {
    let itemIndex = array.findIndex(item => item.id == newItem.id);
    if (itemIndex != -1) {
      return [
        ...array.slice(0, itemIndex),
        newItem,
        ...array.slice(itemIndex + 1)
      ]
    } else {
      if (addIfNotExists) {
        return array.concat(newItem)
      }
    }
  }
}

export function addIfNotExists(array: Array<Base | BaseDTO>, toBeAdded: Base | BaseDTO): Array<Base | BaseDTO> {
  if (array) {
    let itemIndex = array.findIndex(item => item.id == toBeAdded.id);
    if (itemIndex == -1) {
      return array.concat(toBeAdded)
    }
  }
  return array;
}

export function existsInArray(array: Array<Base | BaseDTO>, toBeAdded: Base | BaseDTO) {
  if (array) {
    let itemIndex = array.findIndex(item => item.id == toBeAdded.id);
    return itemIndex != -1;
  }
  return false;
}
