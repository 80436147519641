import { Action, createReducer, on } from '@ngrx/store';
import { existsInArray, replaceInArray } from 'src/app/helpers/array.utils';

import { SlotDTO } from '../../commons/models/slot.model';
import * as SlotActions from '../actions/slot.actions';

export interface SlotState {
  list: SlotDTO[],
  dialogId: string,
  dirtySlotIds: number[]
};

const initialState: SlotState = {
  list: [],
  dialogId: null,
  dirtySlotIds: [],
};

const slotReducer = createReducer(
  initialState,
  on(SlotActions.loadSlotsCompleted, (state, { slots }) => {
    return { ...state, list: slots, dirtySlotIds: [] };
  }),
  on(SlotActions.participantsSelected, (state, { slotId, participants }) => {
    const dirtySlotIds = !state.dirtySlotIds.includes(slotId) ? state.dirtySlotIds.concat(slotId) : state.dirtySlotIds;
    const slot: SlotDTO = JSON.parse(JSON.stringify(state.list.find(slot => slot.id == slotId)));
    slot.participants = slot.participants.concat(participants.filter(p => !existsInArray(slot.participants, p)))
    return { ...state, dirtySlotIds, list: <SlotDTO[]>replaceInArray(state.list, slot) };
  }),
  on(SlotActions.removeParticipantConfirmed, (state, { slotId, participant }) => {
    const dirtySlotIds = !state.dirtySlotIds.includes(slotId) ? state.dirtySlotIds.concat(slotId) : state.dirtySlotIds;
    const slot: SlotDTO = JSON.parse(JSON.stringify(state.list.find(slot => slot.id == slotId)));
    slot.participants = slot.participants.filter(p => p.id != participant.id);
    return { ...state, dirtySlotIds, list: <SlotDTO[]>replaceInArray(state.list, slot) };
  }),
  on(SlotActions.storeParticipantsCompleted, (state, { slot }) => {
    const dirtySlotIds = state.dirtySlotIds.filter(id => id != slot.id);
    return { ...state, dirtySlotIds }
  }),
  on(SlotActions.participantsDialogOpened, (state, { dialogId }) => {
    return { ...state, dialogId };
  })
);

export function reducer(state: SlotState | undefined, action: Action) {
  return slotReducer(state, action);
}

