<thc-participant-filters [showSlotFilters]="!canSelect" (onFilter)="onFilter($event)" [defaultFilters]="defaultFilters">
</thc-participant-filters>
<mat-card class="u-center-text" *ngIf="!(participants | async).length">
  <h4>Nessun partecipante trovato</h4>
  <button *ngIf="canAdd" mat-raised-button (click)="addParticipant()">Aggiungi</button>
</mat-card>
<div [hidden]="!(participants | async).length">
  <table mat-table matSort [dataSource]="participants">
    <ng-container matColumnDef="id">
      <th mat-header-cell *matHeaderCellDef mat-sort-header="id"> ID </th>
      <td mat-cell *matCellDef="let element"> {{element.id}} </td>
    </ng-container>

    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef> Nome </th>
      <td mat-cell *matCellDef="let participant">
        {{participant.fullName}}
        <mat-icon *ngIf="participant.hasUser" matTooltip="Questo partecipante ha un utente associato">person</mat-icon>
      </td>
    </ng-container>

    <ng-container matColumnDef="email">
      <th mat-header-cell *matHeaderCellDef> Email </th>
      <td mat-cell *matCellDef="let participant">
        {{participant.email}}
      </td>
    </ng-container>

    <ng-container matColumnDef="mobile">
      <th mat-header-cell *matHeaderCellDef> Cellulare </th>
      <td mat-cell *matCellDef="let participant">
        {{participant.mobile}}
      </td>
    </ng-container>

    <ng-container matColumnDef="registration_date">
      <th mat-header-cell *matHeaderCellDef> Data registrazione </th>
      <td mat-cell *matCellDef="let participant">
        {{participant.registrationDate | date: 'dd-MM-yyyy'}}
      </td>
    </ng-container>

    <ng-container matColumnDef="type">
      <th mat-header-cell *matHeaderCellDef> Tipologia </th>
      <td mat-cell *matCellDef="let participant">
        <thc-participant-type [type]="participant.type"></thc-participant-type>
        <span *ngIf="!participant.private">{{participant.groupName}}</span>
      </td>
    </ng-container>

    <ng-container matColumnDef="total">
      <th mat-header-cell *matHeaderCellDef> Partecipanti </th>
      <td mat-cell *matCellDef="let participant">
        {{participant.groupSize}}
      </td>
    </ng-container>

    <ng-container matColumnDef="slot">
      <th mat-header-cell *matHeaderCellDef> Slot </th>
      <td mat-cell *matCellDef="let participant">
        <span *ngIf="participant.slot">KM {{participant.slot.km}} Checkpoint {{participant.slot.incremental*100}}</span>
      </td>
    </ng-container>

    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef>
        <button *ngIf="canAdd" mat-icon-button mat-raised-button color="primary"
          (click)="$event.stopPropagation();addParticipant()">
          <mat-icon>playlist_add</mat-icon>
        </button>
      </th>
      <td mat-cell *matCellDef="let participant">
        <ng-container *ngIf="canEdit">
          <button mat-icon-button [mat-menu-trigger-for]="actionMenu">
            <mat-icon>more_vert</mat-icon>
          </button>
          <mat-menu x-position="before" overlapTrigger="false" #actionMenu="matMenu">
            <button mat-menu-item (click)="editParticipant(participant)">
              <mat-icon>create</mat-icon>
              Modifica
            </button>
            <button *ngIf="!participant.hasUser" mat-menu-item (click)="addUser(participant)">
              <mat-icon>person_add</mat-icon>
              Crea utente
            </button>
          </mat-menu>
        </ng-container>
        <button *ngIf="canSelect" [disabled]="maxSize && participant.size > maxSize" mat-raised-button color="primary"
          (click)="selectParticipant(participant)">Seleziona</button>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>

  <mat-paginator [pageSizeOptions]="pageSizeOptions" [pageSize]="pageSizeOptions[0]" [length]="total | async"
    showFirstLastButtons>
  </mat-paginator>
</div>
