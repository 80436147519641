import { createAction, props } from '@ngrx/store';

import { ParticipantDTO } from '../../commons/models/participant.model';
import { SlotDTO } from '../../commons/models/slot.model';

export const loadSlots = createAction('[Slots] Load slots');
export const loadSlotsCompleted = createAction('[Slots] Load slots Completed', props<{ slots: SlotDTO[] }>());
export const loadSlotsFailed = createAction('[Slots] Load slots Failed', props<{ error: any }>());

export const addParticipants = createAction('[Slots] Add participants', props<{ slot: SlotDTO }>());
export const participantsSelected = createAction('[Slots] Participant selected', props<{ slotId: number, participants: ParticipantDTO[] }>());
export const participantsDialogOpened = createAction('[Slots] Participants dialog opened', props<{ dialogId: string }>());
export const closeParticipantsDialog = createAction('[Slots] Close participants dialog');

export const removeParticipant = createAction('[Slots] Remove participant', props<{ slotId: number, participant: ParticipantDTO }>());
export const removeParticipantConfirmed = createAction('[Slots] Remove participant confirmed', props<{ slotId: number, participant: ParticipantDTO }>());
export const removeParticipantCancelled = createAction('[Slots] Remove participant cancelled');

export const storeParticipants = createAction('[Slots] Store participants', props<{ slotId: number, participants: ParticipantDTO[] }>());
export const storeParticipantsCompleted = createAction('[Slots] Store participants completed', props<{ slot: SlotDTO }>());
export const storeParticipantsFailed = createAction('[Slots] Store participants failed', props<{ error: any }>());

export const exportAll = createAction('[Slots] Export All');
export const exportAllCompleted = createAction('[Slots] Export All completed', props<{ file: Blob }>());
export const exportAllFailed = createAction('[Slots] Export All failed', props<{ error: any }>());

export const exportKm = createAction('[Slots] Export KM', props<{ km: number }>());
export const exportKmCompleted = createAction('[Slots] Export KM completed', props<{ km: number, file: Blob }>());
export const exportKmFailed = createAction('[Slots] Export KM failed', props<{ error: any }>());

export const exportSlot = createAction('[Slots] Export Slot', props<{ slot: SlotDTO }>());
export const exportSlotCompleted = createAction('[Slots] Export Slot completed', props<{ slot: SlotDTO, file: Blob }>());
export const exportSlotFailed = createAction('[Slots] Export Slot failed', props<{ error: any }>());
