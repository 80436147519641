<header mat-dialog-title class="u-flex-full">
    <span *ngIf="selectedUser">
    Partecipazioni di {{selectedUser.fullName}}<span *ngIf="participations?.length"> - {{participations?.length}}</span>
    </span>
</header>
<mat-dialog-content>
    <mat-list *ngIf="participations?.length">
        <mat-list-item *ngFor="let participation of participations">
            <div class="avatar" mat-list-avatar [ngStyle]="!!participation.event?.completeUrl && {'background-image':'url('+participation.event?.completeUrl+')'}">
            </div>
            <div class="mat-line__title" mat-line>
                {{participation.event?.name}} </div>
            <div class="mat-line__subtitle" mat-line>
                {{participation.event?.title}} - {{participation.event?.date | date: 'dd MMMM yyyy'}}
            </div>
            <div mat-line class="contributions" *ngIf="participation.contributed">
                <div mat-line>
                    <button mat-flat-button *ngIf="!!participation.image" (click)="downloadAttachment(participation.image)">
            Immagine</button>
                    <button mat-flat-button *ngIf="!!participation.document" (click)="downloadAttachment(participation.document)">
            Allegato</button>
                </div>
                <div class="comment" mat-line *ngIf="!!participation.comment">{{participation.comment}}</div>
            </div>
            <mat-divider></mat-divider>
        </mat-list-item>
    </mat-list>
    <h4 class="u-center-text" *ngIf="!participations?.length">Nessuna partecipazione</h4>
</mat-dialog-content>
<mat-dialog-actions class="u-flex-full">
    &nbsp;
    <button mat-raised-button color="basic" type="button" (click)="close()">Chiudi</button>
</mat-dialog-actions>