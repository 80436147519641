import { Action, createReducer, on } from '@ngrx/store';

import { SignerDTO, SignerFilters } from '../../commons/models/signer.model';
import * as SignerActions from '../actions/signer.actions';

export interface SignerState {
  list: SignerDTO[],
  filters: SignerFilters,
  total: number,
  currentPage: number,
  perPage: number,
  order: string,
  direction: string,
  dialogId: string
};

const initialState: SignerState = {
  list: [],
  filters: null,
  total: 0,
  currentPage: 1,
  perPage: 25,
  order: null,
  direction: null,
  dialogId: null
};

const signerReducer = createReducer(
  initialState,
  on(SignerActions.loadSignersCompleted, (state, { signers, currentPage, total, perPage, order, direction, filters }) => {
    return { ...state, list: signers, currentPage, total, perPage, order, direction, filters };
  }),
  on(SignerActions.changeFilters, (state, { filters }) => {
    return { ...state, currentPage: 1, filters };
  }),
  on(SignerActions.signerDialogOpened, (state, { dialogId }) => {
    return { ...state, dialogId };
  }),

);

export function reducer(state: SignerState | undefined, action: Action) {
  return signerReducer(state, action);
}

