import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AuthGuard } from './commons/guards/auth.guard';
import { UnAuthGuard } from './commons/guards/unauth.guard';

const routes: Routes = [
  {
    path: "",
    loadChildren: () => import('./modules/home/home.module').then(mod => mod.HomeModule),
    canActivate: [AuthGuard]
  },
  { path: 'dashboard', loadChildren: () => import('./modules/home/dashboard/dashboard.module').then(m => m.DashboardModule), canActivate: [AuthGuard] },
  { path: 'profile', loadChildren: () => import('./modules/home/profile/profile.module').then(m => m.ProfileModule), canActivate: [AuthGuard] },
  {
    path: "login",
    loadChildren: () => import('./modules/login/login.module').then(mod => mod.LoginModule),
    canActivate: [UnAuthGuard]
  },
  {
    path: 'confirm-password',
    loadChildren: () => import('./modules/confirm-password/confirm-password.module').then(m => m.ConfirmPasswordModule),
    canActivate: [UnAuthGuard]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  providers: [AuthGuard, UnAuthGuard],
  exports: [RouterModule]
})
export class AppRoutingModule { }
