import { Pipe, PipeTransform } from '@angular/core';

import { Role } from './../../../commons/models/user.model';


@Pipe({
  name: 'userRole'
})
export class RolePipe implements PipeTransform {

  transform(value: Role): unknown {
    switch (value) {
      case "admin":
        return "Amministratore";
      case "user":
        return "Utente";
    }
  }

}
