import { Action, createReducer, on } from '@ngrx/store';
import { TotalsDTO } from 'src/app/commons/services/backend/laravel-participant.service';

import { ParticipantDTO } from '../../commons/models/participant.model';
import * as ParticipantActions from '../actions/participant.actions';
import { ParticipantFilters } from './../../commons/models/participant.model';

export interface ParticipantState {
  list: ParticipantDTO[],
  total: number,
  currentPage: number,
  perPage: number,
  order: string,
  direction: string,
  filters: ParticipantFilters,
  dialogId: string,
  userDialogId: string,
  totals: TotalsDTO
};

const initialState: ParticipantState = {
  list: [],
  total: 0,
  currentPage: 1,
  perPage: 25,
  order: null,
  direction: null,
  filters: null,
  dialogId: null,
  userDialogId: null,
  totals: null
};

const participantReducer = createReducer(
  initialState,
  on(ParticipantActions.loadParticipantsCompleted, (state, { participants, currentPage, total, perPage, order, direction }) => {
    return { ...state, list: participants, currentPage, total, perPage, order, direction };
  }),
  on(ParticipantActions.participantDialogOpened, (state, { dialogId }) => {
    return { ...state, dialogId };
  }),
  on(ParticipantActions.userDialogOpened, (state, { dialogId }) => {
    return { ...state, userDialogId: dialogId };
  }),
  on(ParticipantActions.loadTotalsCompleted, (state, { totals }) => {
    return { ...state, totals };
  }),
  on(ParticipantActions.changeFilters, (state, { filters }) => {
    return { ...state, filters };
  })
);

export function reducer(state: ParticipantState | undefined, action: Action) {
  return participantReducer(state, action);
}

