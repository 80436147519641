import { createAction, props } from '@ngrx/store';

import { EventDTO } from '../../commons/models/participation.model';
import { AttachmentDTO, ParticipationDTO } from './../../commons/models/participation.model';

export const loadEvents = createAction('[Events] Load events', props<{ page: number, perPage: number, order?: string, direction?: string }>());
export const loadEventsCompleted = createAction('[Events] Load events Completed', props<{ events: EventDTO[], currentPage: number, total: number, perPage: number, order?: string, direction?: string }>());
export const loadEventsFailed = createAction('[Events] Load events Failed', props<{ error: any }>());

export const changePage = createAction('[Events] Change page', props<{ page: number, size: number }>());
export const changeSort = createAction('[Events] Change sort', props<{ order: string, direction: string }>());

export const addEvent = createAction('[Events] Add event');
export const editEvent = createAction('[Events] Edit event', props<{ event: EventDTO }>());
export const eventDialogOpened = createAction('[Events] Detail dialog opened', props<{ dialogId: string }>());
export const closeEventDialog = createAction('[Events] Close detail dialog');

export const saveEvent = createAction('[Events] Save event', props<{ event: EventDTO }>());
export const saveEventCompleted = createAction('[Events] Save event completed', props<{ event: EventDTO }>());
export const saveEventFailed = createAction('[Events] Save event failed', props<{ error: any }>());

export const showParticipations = createAction('[Events] Show participations', props<{ event: EventDTO }>());
export const loadParticipations = createAction('[Events] Load participations', props<{ event: EventDTO }>());
export const loadParticipationsCompleted = createAction('[Events] Load participations Completed', props<{ participations: ParticipationDTO[] }>());
export const loadParticipationsFailed = createAction('[Events] Load participations Failed', props<{ error: any }>());
export const participationsDialogOpened = createAction('[Events] Participations dialog opened', props<{ dialogId: string }>());
export const closeParticipationsDialog = createAction('[Events] Close participations dialog');

export const approveAttachment = createAction('[Events] Approve attachment', props<{ attachment: AttachmentDTO, approved: boolean }>());
export const approveAttachmentCompleted = createAction('[Events] Approve attachment completed', props<{ attachment: AttachmentDTO }>());
export const approveAttachmentFailed = createAction('[Events] Approve attachment failed', props<{ error: any }>());

export const approveComment = createAction('[Events] Approve comment', props<{ participation: ParticipationDTO, approved: boolean }>());
export const approveCommentCompleted = createAction('[Events] Approve comment completed', props<{ participation: ParticipationDTO }>());
export const approveCommentFailed = createAction('[Events] Approve comment failed', props<{ error: any }>());

export const updateComment = createAction('[Events] Update comment', props<{ participationId: number, comment: string }>());
export const updateCommentCompleted = createAction('[Events] Update comment completed', props<{ participation: ParticipationDTO }>());
export const updateCommentFailed = createAction('[Events] Update comment failed', props<{ error: any }>());

export const deleteEvent = createAction('[Events] Delete event', props<{ event: EventDTO }>());
export const deleteEventCompleted = createAction('[Events] Delete event completed', props<{ event: EventDTO }>());
export const deleteEventCancelled = createAction('[Events] Delete event cancelled');
export const deleteEventFailed = createAction('[Events] Delete event failed', props<{ error: any }>());

export const exportParticipations = createAction('[Events] Export participations', props<{ event: EventDTO }>());
export const exportParticipationsCompleted = createAction('[Events] Export participations Completed', props<{ event: EventDTO, file: Blob }>());
export const exportParticipationsFailed = createAction('[Events] Export participations Failed', props<{ error: any }>());
