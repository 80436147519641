import { Action, createReducer, on } from '@ngrx/store';

import * as ConfirmActions from '../actions/confirm-account.actions';
import { ParticipantDTO } from './../../commons/models/participant.model';


export interface ConfirmAccountState {
  participant: ParticipantDTO
};

const initialState: ConfirmAccountState = {
  participant: null,
};

const confirmReducer = createReducer(
  initialState,
  on(ConfirmActions.getParticipantByCodeCompleted, (state, { participant }) => {
    return { ...state, participant };
  }),
);


export function reducer(state: ConfirmAccountState | undefined, action: Action) {
  return confirmReducer(state, action);
}
