import { Component, Inject, OnInit, Optional } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { select, Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { filter, map, takeUntil } from 'rxjs/operators';
import { User } from 'src/app/commons/models/user.model';
import * as EventActions from 'src/app/store/actions/event.actions';
import * as ParticipationActions from 'src/app/store/actions/participation.actions';
import * as UserActions from 'src/app/store/actions/user.actions';
import { AppState } from 'src/app/store/reducers';
import * as UserSelectors from 'src/app/store/selectors/user.selectors';

import { Attachment, Participation } from './../../../../commons/models/participation.model';


@Component({
  templateUrl: './user-participations.component.html',
  styleUrls: ['./user-participations.component.scss']
})
export class UserParticipationsComponent implements OnInit {

  private unsubscribe$ = new Subject<void>();

  selectedUser: User;
  participations: Participation[];

  constructor(private store$: Store<AppState>, @Optional() @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit() {
    if (this.data && this.data.user) {
      this.selectedUser = new User(this.data.user);
    }
    this.store$.pipe(select(UserSelectors.getUserParticipations), takeUntil(this.unsubscribe$), map(dtos => dtos ? dtos.map(dto => new Participation(dto)) : null)).subscribe(participations => this.participations = participations);
    this.store$
      .pipe(
        select(UserSelectors.getUserParticipationsLoaded),
        takeUntil(this.unsubscribe$),
        filter((loaded) => !loaded)
      )
      .subscribe(() => this._loadParticipations());
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  private _loadParticipations() {
    if (this.selectedUser) {
      this.store$.dispatch(UserActions.loadParticipations({ user: this.selectedUser.toDTO() }));
    }
  }

  approveAttachment(attachment: Attachment, approved: boolean) {
    if (attachment) {
      this.store$.dispatch(EventActions.approveAttachment({ attachment: attachment.toDTO(), approved }));
    }
  }

  downloadAttachment(attachment: Attachment) {
    if (attachment) {
      this.store$.dispatch(ParticipationActions.downloadAttachment({ attachment: attachment.toDTO() }));
    }
  }

  close() {
    this.store$.dispatch(UserActions.closeParticipationsDialog());
  }

}
