import { registerLocaleData } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import localeIt from '@angular/common/locales/it';
import { APP_INITIALIZER, LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { Actions, EffectsModule, ofType } from '@ngrx/effects';
import { StoreRouterConnectingModule } from '@ngrx/router-store';
import { Store, StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { NgxUiLoaderConfig, NgxUiLoaderHttpModule, NgxUiLoaderModule, SPINNER } from 'ngx-ui-loader';
import { take } from 'rxjs/operators';

import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { TokenInterceptorService } from './helpers/token-interceptor.service';
import { MaterialModule } from './modules/material/material.module';
import { SharedModule } from './modules/shared/shared.module';
import * as AuthActions from './store/actions/auth.actions';
import { AuthEffects } from './store/effects/auth.effects';
import { ParticipationEffects } from './store/effects/participation.effects';
import { RouterEffects } from './store/effects/router.effects';
import { UserEffects } from './store/effects/user.effects';
import { AppState, metaReducers, reducers } from './store/reducers';

registerLocaleData(localeIt);

const ngxUiLoaderConfig: NgxUiLoaderConfig = {
  fgsColor: "#2c3031",
  fgsType: SPINNER.threeBounce,
  hasProgressBar: false,
  overlayColor: "rgba(130,130,130,0.7)"
};

export function initApplication(store: Store<AppState>, actions$: Actions): Function {
  return () => new Promise(resolve => {
    store.dispatch(AuthActions.loadCurrentUser());
    actions$.pipe(ofType(AuthActions.loadCurrentUserCompleted, AuthActions.loadCurrentUserFailed), take(1)).subscribe(() => resolve(true))
  })
}

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    SharedModule,
    HttpClientModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    NgxUiLoaderModule.forRoot(ngxUiLoaderConfig),
    NgxUiLoaderHttpModule.forRoot({
      showForeground: true
    }),
    StoreModule.forRoot(reducers, {
      metaReducers,
      runtimeChecks: {
        strictStateImmutability: true,
        strictActionImmutability: true
      }
    }),
    EffectsModule.forRoot([AuthEffects, RouterEffects, ParticipationEffects, UserEffects]),
    MaterialModule,
    StoreRouterConnectingModule.forRoot(),
    StoreDevtoolsModule.instrument({ maxAge: 25, logOnly: environment.production }),
  ],
  providers: [
    AuthEffects,
    {
      provide: APP_INITIALIZER,
      useFactory: initApplication,
      deps: [Store, Actions],
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptorService,
      multi: true
    },
    {
      provide: LOCALE_ID,
      useValue: "it-IT"
    },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
