import { createAction, props } from '@ngrx/store';
import { TotalsDTO } from 'src/app/commons/services/backend/laravel-participant.service';

import { Participant, ParticipantDTO } from '../../commons/models/participant.model';
import { ParticipantFilters } from './../../commons/models/participant.model';

export const loadParticipants = createAction('[Participants] Load participants', props<{ page: number, perPage: number, order?: string, direction?: string, filters?: ParticipantFilters }>());
export const loadParticipantsCompleted = createAction('[Participants] Load participants Completed', props<{ participants: ParticipantDTO[], currentPage: number, total: number, perPage: number, order?: string, direction?: string, filters?: ParticipantFilters }>());
export const loadParticipantsFailed = createAction('[Participants] Load participants Failed', props<{ error: any }>());

export const changePage = createAction('[Participants] Change page', props<{ page: number, size: number }>());
export const changeSort = createAction('[Participants] Change sort', props<{ order: string, direction: string }>());
export const changeFilters = createAction('[Participants] Change filters', props<{ filters: ParticipantFilters }>());

export const addParticipant = createAction('[Participants] Add participant');
export const editParticipant = createAction('[Participants] Edit participant', props<{ participant: Participant }>());
export const participantDialogOpened = createAction('[Participants] Detail dialog opened', props<{ dialogId: string }>());
export const closeParticipantDialog = createAction('[Participants] Close detail dialog');

export const addUser = createAction('[Participants] Add user', props<{ participant: ParticipantDTO }>());
export const userDialogOpened = createAction('[Participants] User dialog opened', props<{ dialogId: string }>());
export const closeUserDialog = createAction('[Participants] Close user dialog');

export const saveUser = createAction('[Participants] Save user', props<{ participant: ParticipantDTO, password: string }>());
export const saveUserCompleted = createAction('[Participants] Save user completed', props<{ participant: ParticipantDTO }>());
export const saveUserFailed = createAction('[Participants] Save user failed', props<{ error: any }>());

export const saveParticipant = createAction('[Participants] Save participant', props<{ participant: Participant }>());
export const saveParticipantCompleted = createAction('[Participants] Save participant completed', props<{ participant: ParticipantDTO }>());
export const saveParticipantFailed = createAction('[Participants] Save participant failed', props<{ error: any }>());

export const loadTotals = createAction('[Participants] Load totals');
export const loadTotalsCompleted = createAction('[Participants] Load totals completed', props<{ totals: TotalsDTO }>());
export const loadTotalsFailed = createAction('[Participants] Load totals failed', props<{ error: any }>());

export const deleteParticipant = createAction('[Participants] Delete participant', props<{ participant: Participant }>());
export const deleteParticipantCompleted = createAction('[Participants] Delete participant completed', props<{ participant: ParticipantDTO }>());
export const deleteParticipantCancelled = createAction('[Participants] Delete participant cancelled');
export const deleteParticipantFailed = createAction('[Participants] Delete participant failed', props<{ error: any }>());

export const exportParticipants = createAction('[Participants] Export participants');
export const exportParticipantsCompleted = createAction('[Participants] Export participants completed', props<{ file: Blob }>());
export const exportParticipantsFailed = createAction('[Participants] Export participants failed', props<{ error: any }>());
