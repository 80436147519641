import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ListResultDTO } from 'src/app/helpers/listResult.interface';
import { environment } from 'src/environments/environment';

import { AttachmentDTO, ParticipationDTO } from '../../models/participation.model';
import { AttachmentType } from './../../models/participation.model';

export interface ParticipationTotalsDTO {
  "participations": number,
  "images": number,
  "comments": number,
  "attachments": number,
}

@Injectable({
  providedIn: "root"
})
export class ParticipationService {
  constructor(private httpClient: HttpClient) { }

  private get ROUTES() {
    return {
      byEventUser: environment.baseUrl + "/api/participation/by_event_user",
      list: environment.baseUrl + "/api/participations",
      store: environment.baseUrl + "/api/participations",
      byUser: environment.baseUrl + "/api/users/participations",
      totals: environment.baseUrl + "/api/totals/participations",
    };
  }

  public totals(): Observable<ParticipationTotalsDTO> {
    return this.httpClient.get<ParticipationTotalsDTO>(`${this.ROUTES.totals}`);
  }

  public getByUser(userId: number): Observable<ParticipationDTO[]> {
    return this.httpClient.get<ParticipationDTO[]>(`${this.ROUTES.byUser}/${userId}`);
  }

  public getById(id: number): Observable<ParticipationDTO> {
    return this.httpClient.get<ParticipationDTO>(`${this.ROUTES.list}/${id}`);
  }

  public getByEventAndUser(event_id: number, user_id: number): Observable<ParticipationDTO> {
    let params = {
      event_id: "" + event_id,
      user_id: "" + user_id
    }
    return this.httpClient.get<ParticipationDTO>(this.ROUTES.byEventUser, {
      params: new HttpParams({ fromObject: params })
    });
  }

  public list(page: number,
    per_page: number,
    order: string,
    direction: string
  ): Observable<ListResultDTO<ParticipationDTO>> {
    let params = {};
    if (order) params["order"] = "" + order;
    if (direction) params["direction"] = "" + direction;

    if (per_page) params["per_page"] = "" + per_page;
    if (page) params["page"] = "" + page;
    return this.httpClient.get<ListResultDTO<ParticipationDTO>>(this.ROUTES.list, {
      params: new HttpParams({ fromObject: params })
    });
  }

  public delete(id: number): Observable<any> {
    return this.httpClient.delete(`${this.ROUTES.list}/${id}`);
  }

  public upsert(participation: ParticipationDTO): Observable<ParticipationDTO> {
    if (participation.id) {
      return this.httpClient.put<ParticipationDTO>(`${this.ROUTES.store}/${participation.id}`, participation);
    } else {
      return this.httpClient.post<ParticipationDTO>(`${this.ROUTES.store}`, participation);
    }
  }

  public updateComment(id: number, comment: string): Observable<ParticipationDTO> {
    return this.httpClient.patch<ParticipationDTO>(`${environment.baseUrl}/api/participation/${id}/comment`, { comment });
  }

  public attachments(participationId: number): Observable<ListResultDTO<AttachmentDTO>> {
    return this.httpClient.get<ListResultDTO<AttachmentDTO>>(`${environment.baseUrl}/api/participations/${participationId}/attachments`, {
      params: new HttpParams({ fromObject: { per_page: "99999" } })
    });
  }

  public addAttachments(participationId: number, attachments: File[], type: AttachmentType): Observable<AttachmentDTO[]> {
    const formData: FormData = new FormData();
    if (attachments) {
      attachments.forEach((attachment, index) => {
        formData.append(`attachment_${index}`, attachment)
      })
      formData.append('count', "" + attachments.length);
      formData.append('type', "" + type);
    }

    return this.httpClient.post<AttachmentDTO[]>(`${environment.baseUrl}/api/participations/${participationId}/attachments`, formData);
  }

  public removeAttachment(participationId: number, attachmentId: number): Observable<any> {
    return this.httpClient.delete<any>(`${environment.baseUrl}/api/participations/${participationId}/attachments/${attachmentId}`);
  }

  public approveAttachment(attachmentId: number, approved: boolean = true): Observable<AttachmentDTO> {
    return this.httpClient.patch<AttachmentDTO>(`${environment.baseUrl}/api/attachments/${attachmentId}/approve`, { approved });
  }

  public approveComment(participationId: number, approved: boolean = true): Observable<ParticipationDTO> {
    return this.httpClient.patch<ParticipationDTO>(`${environment.baseUrl}/api/participation/${participationId}/comment/approve`, { approved });
  }
}
