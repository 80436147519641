<div class="image-upload">
  <input id="image-input" type="file" class="image-upload__input" [disabled]="disabled" (change)="onFileChange($event)"
    accept=".jpg,.jpeg,.png,.JPG,.JPEG,.PNG">
  <img [src]="imageSrc" [appImgFallback]="DEFAULT_PLACEHOLDER_SRC" class="image-upload__image" alt="image-preview">

  <button mat-mini-fab *ngIf="file" [disabled]="disabled" color="warn" (click)="delete()" class="image-upload__delete"
    size="small">
    <mat-icon>delete</mat-icon>
  </button>
</div>
