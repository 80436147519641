import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';

import { ParticipantFilters } from '../../../commons/models/participant.model';
import { ParticipantType } from './../../../commons/models/participant.model';

@Component({
  selector: 'thc-participant-filters',
  templateUrl: './participant-filters.component.html',
  styleUrls: ['./participant-filters.component.scss']
})
export class ParticipantFiltersComponent {

  types: ParticipantType[] = ["Singolo", "Gruppo", "Scuola", "Ente locale"];

  @Input()
  showSlotFilters: boolean = true;

  @Output()
  onFilter: EventEmitter<ParticipantFilters> = new EventEmitter();

  private _defaultFilters: ParticipantFilters;
  filtersForm: FormGroup;

  constructor(private fb: FormBuilder) {
    this._createForm();
  }

  private _createForm() {
    let group = {
      withSlot: [true],
      withoutSlot: [true],
      search: "",
      registrationStart: [],
      registrationEnd: [],
      types: [["Singolo", "Gruppo", "Scuola", "Ente locale"]],
      eventName: "",
    }

    this.filtersForm = this.fb.group(group);
  }

  applyFilters() {
    this.onFilter.emit(this._getParticipantFilters());
  }

  private _getParticipantFilters(): ParticipantFilters {
    let filters: ParticipantFilters = {}
    if (this.filtersForm) {
      const values = this.filtersForm.value;
      filters.excludeWithSlot = !values.withSlot;
      filters.excludeWithoutSlot = !values.withoutSlot;
      filters.search = values.search;
      filters.registrationStart = values.registrationStart;
      filters.registrationEnd = values.registrationEnd;
      filters.types = values.types;
      filters.eventName = values.eventName;
    }
    return filters;
  }

  resetFilters() {
    this.filtersForm.reset();
    this.filtersForm.patchValue({
      withSlot: true,
      withoutSlot: true,
      search: "",
      registrationStart: null,
      registrationEnd: null,
      types: [],
      eventName: ""
    });
    this._setDefaultValues();
    this.applyFilters();
  }

  get defaultFilters(): ParticipantFilters {
    return this._defaultFilters;
  }

  @Input()
  set defaultFilters(defaultFilters: ParticipantFilters) {
    this._defaultFilters = defaultFilters;
    this._setDefaultValues();
  }

  private _setDefaultValues() {
    if (this.defaultFilters) {
      if (this.filtersForm) {
        this.filtersForm.patchValue({
          withSlot: !this.defaultFilters.excludeWithSlot,
          withoutSlot: !this.defaultFilters.excludeWithoutSlot,
          search: this.defaultFilters.search,
          registrationStart: this.defaultFilters.registrationStart,
          registrationEnd: this.defaultFilters.registrationEnd,
          types: this.defaultFilters.types,
          eventName: this.defaultFilters.eventName
        })
      }
    }
  }
}
