import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { catchError, map, mergeMap, take } from 'rxjs/operators';
import * as AuthActions from 'src/app/store/actions/auth.actions';
import { AppState } from 'src/app/store/reducers';
import * as AuthSelectors from 'src/app/store/selectors/auth.selectors';

@Injectable({
  providedIn: "root"
})
export class TokenInterceptorService implements HttpInterceptor {
  constructor(private store: Store<AppState>,) { }


  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return this.store.pipe(
      select(AuthSelectors.getAuthToken),
      take(1),
      mergeMap(token => {
        let clonedReq = this.addToken(req, token);
        return next.handle(clonedReq).pipe(
          map(resp => {
            // on Response
            if (resp instanceof HttpResponse) {
              // Do whatever you want with the response.
              return resp;
            }
          }),
          catchError(err => {
            if (err instanceof HttpErrorResponse) {
              if (token && err.status === 401) {
                this.store.dispatch(AuthActions.logoutCompleted());
              }
            }
            throw err;
          })
        );
      })
    );
  }

  /**
   * Adds the token to your headers if it exists
   * @param request
   * @param token
   */
  private addToken(request: HttpRequest<any>, token: any) {
    if (token) {
      let clone: HttpRequest<any>;
      clone = request.clone({
        setHeaders: {
          Accept: "application/json",
          Authorization: `Bearer ${token}`
        }
      });
      return clone;
    }
    return request;
  }

}
