import { createAction, props } from '@ngrx/store';

import { ParticipantDTO } from './../../commons/models/participant.model';

export const getParticipantByCode = createAction('[Confirm account] Get participant by id and mail', props<{ id: number, email: string }>());
export const getParticipantByCodeCompleted = createAction('[Confirm account] Get participant by id and mail completed', props<{ participant: ParticipantDTO }>());
export const getParticipantByCodeFailed = createAction('[Confirm account] Get participant by id and mail failed', props<{ error: any }>());

export const confirmParticipant = createAction('[Confirm account] Confirm participant', props<{ participant: ParticipantDTO, password: string }>());
export const confirmParticipantCompleted = createAction('[Confirm accounts] Save participant completed', props<{ participant: ParticipantDTO, password: string }>());
export const confirmParticipantFailed = createAction('[Confirm account] Save participant failed', props<{ error: any }>());
